/*
export enum EAttachmentEnumBase {
    IdentityDocument = 'identity',
    InsurancePolicy = 'policy',
    Other = 'other',
}

export enum EAttachmentHeathInsuranceEnum {
    Lamal = 'lamal',
    Lca = 'lca',
    LamalLca = 'lamalLca',
    DualAffiliate = "dualAffiliate",
    TerminationLetter = 'terminationLetter',
}

export type TAttachmentHeathInsurance = EAttachmentEnumBase | EAttachmentHeathInsuranceEnum
export const AttachmentHeathInsurance = {...EAttachmentEnumBase, ...EAttachmentHeathInsuranceEnum} as const
*/

export const AttachmentTypeBase = {
    IdentityDocument: 'identity',
    Other: 'other',
}

export const AttachmentTypeHealthInsurance = {
    Lamal: 'lamal',
    Lca: 'lca',
    LamalLca: 'lamalLca',
    DualAffiliate: "dualAffiliate",
    TerminationLetter: 'terminationLetter',
    InsurancePolicy: 'policy',
    Lsa45: 'lsa45',
    ...AttachmentTypeBase,
}

export type TAttachmentTypeHealthInsurance = typeof AttachmentTypeHealthInsurance[keyof typeof AttachmentTypeHealthInsurance]

export const AttachmentTypeLpp = {
    FreeMovement: 'freeMovement',
    Proxy: 'proxy',
    PensionLpp: 'pensionLpp',
    BenefitStatement: 'benefitStatement',
    Signature: 'signature',
    ...AttachmentTypeBase,
}

export type TAttachmentTypeLpp = typeof AttachmentTypeLpp[keyof typeof AttachmentTypeLpp]