import React from 'react'
import { Svg } from './Svg'

export function ChevronRight(props) {
    return (
        <Svg {...props}>
            <path d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </Svg>
    )
}
