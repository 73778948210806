import { useCallback, useState } from 'react'

export const useBoolean = (initial = false) => {
    const [state, setValue] = useState(initial)

    const toggle = useCallback(() => {
        setValue((s) => {
            return !s
        })
    }, [initial])

    const setState = useCallback(
        (v) => {
            setValue(v)
        },
        [initial]
    )

    const setFalse = useCallback(() => {
        setValue(false)
    }, [initial])

    const setTrue = useCallback(() => {
        setValue(true)
    }, [initial])

    return {
        state,
        toggle,
        setState,
        setTrue,
        setFalse
    }
}
