import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef, MouseEventHandler, useCallback } from "react"
import { ReactChildren } from "../../../types"
import { Play } from "../../../icons/Play"
import { Pause } from "../../../icons/Pause"
import { ChevronDown, ChevronUp, SpeakerHigh, SpeakerLow, SpeakerMuted, Stop } from "../../../icons"
import { padWithLeadingChar, uniqId } from "../../../libs"


type AudioAssignerType = (label: string, base64: string) => void

interface AudioProps {
    url?: string | string[] | null
    autoPlay?: boolean
    label?: string | string[] | ReactChildren
    volume?: number
    onError?: (error: string) => void
    playlist?: (assign: AudioAssignerType) => ReactChildren
}

enum AudioPlayerStatus {
    Stop = 'stop',
    Pause = 'pause',
    Play = 'play',
    Waiting = 'waiting',
    Error = 'error',
}

enum AudioCodec {
    Opus = 'audio/ogg; codecs=opus',
    Vorbis = 'audio/ogg; codecs=vorbis',
    Caf = 'audio/x-caf; codecs=opus',
    M4a = 'audio/mp4; codecs=mp4a.40.2',
    Flac = 'audio/flac',
    Wav = 'audio/wav',
}

function checkCanPlay(audio: HTMLAudioElement): boolean {
    Object.values(AudioCodec).forEach(codec => {
        if(audio.canPlayType(codec) === 'probably') {
            return true
        }
    })

    return false
}

function formatDuration(duration: number | string): string {

    if(typeof duration === 'string') {
        return duration
    }

    let minute = 0
    let hour = 0
    let second = parseInt(duration + '', 10)


    if(second > 3600) {
        hour = parseInt((second / 3600) + '', 10)
        second -= hour * 3600
    }

    if(second > 60) {
        minute = parseInt((second / 60) + '', 10)
        second -= minute * 60
    }

    return (hour ? padWithLeadingChar(hour, 2) + ':' : '') + padWithLeadingChar(minute, 2) + ':' + padWithLeadingChar(second, 2)
}

function progressBar(current: number | string, max: number): number {
    if(typeof current === 'string') {
        return 0
    }
    return current / max * 100
}

const AudioPlayer = forwardRef(
    (
        {
            url: _url = [],
            label: _label = [],
            volume = 50,
            autoPlay = false,
            onError = () => {},
            playlist,
        }: AudioProps,
        ref
    ) => {

        const ctx = new AudioContext()
        //const volumeCtx = ctx.createGain()
        //const oscillator = new OscillatorNode(ctx);

        const [url, setUrl] = useState(_url)
        const [label, setLabel] = useState(_label)
        const [header, setHeader] = useState('')
        const [selected, setSelected] = useState(-1)
        const [status, setStatus] = useState(AudioPlayerStatus.Waiting)
        const [open, setOpen] = useState(false)
        const [playlistOpen, setPlaylistOpen] = useState(false)
        const [vol, setVol] = useState(volume)
        //const [captation, setCaptation] = useState(false)
        const captation = useRef(false)
        const alreadyStart = useRef(false)
        const audio = useRef<HTMLAudioElement|null>(null)
        const [current, setCurrent] = useState<number|string>('--:--')
        const [max, setMax] = useState<number|string>('--:--')

        useEffect(() => {
            setSelected(0)
        }, [url])

        /*
        if(test) {
            const source = ctx.createMediaElementSource(audio)
            source.connect(ctx.destination)
        } else {
            onError('Can not read audio')
            if(status === AudioPlayerStatus.Error) setStatus(AudioPlayerStatus.Error)
        }
        */

        useEffect(() => {
            if(selected !== -1 && url?.length > 0) {
                const defaultUrl = Array.isArray(url) ? url[selected] : url

                let h = ''
                if(Array.isArray(label)) {
                    h = label[selected]
                } else if(label) {
                    h = label
                } else {
                    h = (Array.isArray(url) ? url[selected] : url).replace(/.+\/(\w+\.[a-z]{3,4})/g, '$1')
                }
                setHeader(h)

                audio.current = new Audio(defaultUrl ?? undefined)
                audio.current.onerror = () => {
                    setStatus(AudioPlayerStatus.Error)
                }

                audio.current.onended = () => {
                    setStatus(AudioPlayerStatus.Stop)
                }

                audio.current.ondurationchange = () => {
                    setMax(audio.current.duration)
                }

                audio.current.ontimeupdate = () => {
                    setCurrent(audio.current.currentTime)
                }

                audio.current.volume = vol / 100

                //const test = checkCanPlay(audio.current)

                return  () => {
                    if(audio.current) {
                        audio.current.pause()
                    }
                }
            }

        }, [selected, url])

        useEffect(() => {
            switch(status) {
                case AudioPlayerStatus.Play:
                    audio.current?.play()
                    alreadyStart.current = true
                    break
                case AudioPlayerStatus.Pause:
                    audio.current?.pause()
                    alreadyStart.current = false
                    break
                case AudioPlayerStatus.Stop:
                    audio.current?.pause()
                    if(audio.current) audio.current.currentTime = 0
                    setCurrent(0)
                    alreadyStart.current = false
                    break
            }
        }, [status])

        useEffect(() => {
            if(audio.current) {
                audio.current.volume = vol / 100
            }
        }, [vol])

        useEffect(() => {
            if(autoPlay) {
                play()
            }
        }, [autoPlay])

        const play = useCallback(() => {
            if(url) {
                console.log('PLAY')
                setStatus(AudioPlayerStatus.Play)
            }
        }, [url])

        const pause = useCallback(() => {
            if(url) {
                console.log('PAUSE')
                setStatus(AudioPlayerStatus.Pause)
            }
        }, [url])

        const toggle = useCallback(() =>  {
            if(url) {
                status === AudioPlayerStatus.Play ? pause() : play()
            }
        }, [url, status])

        useImperativeHandle(ref, () => ({
            play: () => {
                play()
            },
            pause: () => {
                pause()
            },
            toggle: () => {
                toggle()
            },
            volume: (value: number) => {
                audio.current.volume = vol / 100
            },
            load: (url: string) => {
                audio.current = new Audio(url)
            }
        }))

        function move(e: MouseEventHandler<HTMLDivElement>, force: boolean) {
            if((captation.current || force) && url) {
                const rect = e.target.getBoundingClientRect()
                const clickYRelativeToElement = event.clientY - rect.top;

                let percentage = 100 - (clickYRelativeToElement / e.target.clientHeight) * 100

                if(percentage < 0) {
                    percentage = 0
                } else if(percentage > 100) {
                    percentage = 100
                }

                setVol(percentage)
            }
        }

        function move2(e: MouseEventHandler<HTMLDivElement>, force: boolean) {
            if((captation.current || force) && url) {
                const rect = e.target.getBoundingClientRect()
                const clickXRelativeToElement = event.clientX - rect.left;

                let percentage = (clickXRelativeToElement / e.target.clientWidth) * 100

                if(percentage < 0) {
                    percentage = 0
                } else if(percentage > 100) {
                    percentage = 100
                }

                audio.current?.pause()
                const value = max * percentage / 100
                setCurrent(value)
                audio.current.currentTime = value
            }
        }

        let timer = null
        function inside(callback: () => void): void {
            if(url) {
                captation.current = true
                callback()
            }

            if(timer) {
                clearTimeout(timer)
            }
        }

        function outside() {
            if(url) {
                captation.current = false
                if(alreadyStart.current)  {
                    audio.current?.play()
                }

                timer = setTimeout(() => {
                    setOpen(false)
                }, 5000)
            }
        }

        function stop() {
            setStatus(AudioPlayerStatus.Stop)
        }

        const openClose = open ? 'audio-player-volume-bar-wrapper-open' : 'audio-player-volume-bar-wrapper-close'

        const assign:AudioAssignerType = (label, base64) => {
            setStatus(AudioPlayerStatus.Stop)
            setLabel([label])
            setUrl([base64])
        }

        const haveLabel = label?.length > 0

        return (
            <div className="audio-player">
                <div className={"relative flex sm:justify-center sm:items-center items-start justify-between flex-wrap " + (status) + ' ' + (open ? 'open': 'close')}>
                    <div className="absolute left-[3rem] top-[0.3rem]">
                        <div className={`cursor-pointer audio-player-play-button-stop dark:bg-color7 bg-color3${!haveLabel ? ' opacity-0': ''}`} onClick={stop}>
                            {
                                typeof current === 'number' && current > 0 ? <Stop className="w-3 h-3" /> : <Stop className="w-3 h-3 opacity-10" />
                            }
                        </div>
                    </div>
                    <div className={`p-1 audio-player-play-button order-3 sm:order-1${!haveLabel ? ' opacity-20': ' cursor-pointer'}`} onClick={() => {if(haveLabel) toggle()}}>
                        {
                            status === AudioPlayerStatus.Play ?
                                <Pause className="w-6 h-6" />
                            :
                                <Play className="w-6 h-6" />
                        }
                    </div>
                    <div className="select-none text-xs px-1 sm:px-4 sm:pt-4 order-2">
                        { formatDuration(current) }
                    </div>
                    <div className="flex-1 order-1 sm:order-3 basis-full sm:basis-auto">
                        <div className={`select-none z-10 relative max-w-full audio-player-label${!haveLabel ? ' opacity-20': ''}`}>
                            <div className={"flex" + (haveLabel ? ' cursor-pointer' : '')} onClick={() => { if(label?.length > 1) setPlaylistOpen(!playlistOpen)} }>
                                <div className="flex-1 text-ellipsis whitespace-nowrap overflow-hidden">{haveLabel ? header : 'Aucun fichier à lire'}</div>
                                {
                                    label?.length > 1 ?
                                    <div>
                                        {
                                            playlistOpen ?
                                                <ChevronUp className="mt-1 w-4 h-4 dark:fill-white fill-color6" />
                                            :
                                                <ChevronDown className="mt-1 w-4 h-4 dark:fill-white fill-color6" />
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                            {
                                label?.length > 1 ?
                                    <div className={"transition-all mt-1 duration-200 absolute w-full flex flex-col text-sm rounded-lg bg-color2 dark:bg-color6 border-white/10 overflow-auto " + (playlistOpen ? 'h-36 shadow-lg shadow-black/10 border' : 'h-0')}>
                                    {
                                        label?.map((l, i) => (
                                            <div key={uniqId()} className="dark:hover:bg-color9/10 hover:bg-color5/10 py-2 px-3 cursor-pointer" onClick={ () => { setSelected(i); setPlaylistOpen(false) } }>{l}</div>
                                        ))
                                    }
                                    </div>
                                : null
                            }
                        </div>
                        <div
                            className={`h-4 overflow-hidden audio-player-bar-wrapper${!haveLabel ? ' opacity-30': ''}`}
                            onMouseDown={(e) => {inside(() => move2(e, true))}}
                            onMouseUp={() => {outside()}}
                            onMouseLeave={outside}
                            onMouseMove={move2}
                        >
                            <div className="pointer-events-none audio-player-bar" style={ {width: progressBar(current, max) + '%'} } />
                        </div>
                    </div>
                    <div className="select-none text-xs px-1 sm:px-4 sm:pt-4 sm:order-4 order-6">
                        { formatDuration(max) }
                    </div>
                    <div className="p-1 absolute bottom-1 right-3 sm:relative sm:right-auto sm:bottom-auto flex audio-player-volume order-5">
                        <div className="z-10 audio-player-volume-button pointer-events-none">
                            <div className={`text-xxs hidden sm:block text-center pb-1 h-5${!haveLabel ? ' opacity-20 ': ''}`}>{ !open ? parseInt(vol + '', 10) + '%' : null }</div>
                            <div className={`pointer-events-auto audio-player-volume-button-icon${!haveLabel ? ' opacity-20 ': ' cursor-pointer'}`} onClick={() => {if(haveLabel)setOpen(!open)}}>
                                {
                                    vol > 0 ? vol < 51 ? <SpeakerLow className="w-6 h-6" /> : <SpeakerHigh className="w-6 h-6" /> : <SpeakerMuted className="opacity-20 w-6 h-6" />
                                }
                            </div>
                        </div>
                        <div className={`z-0 transition-all duration-200 absolute -left-1 -bottom-1 w-10 overflow-hidden ${openClose} ${ open ? 'h-36': 'h-10'}`}>
                            <div className={`text-xxs mt-2 text-center select-none`}>
                                { parseInt(vol + '', 10)}%
                            </div>
                            <div
                                className="w-4 h-[5rem] mx-auto mt-1 overflow-hidden flex items-end audio-player-volume-bar-wrapper"
                                onMouseDown={(e) => {inside(() => move(e, true))}}
                                onMouseUp={outside}
                                onMouseLeave={outside}
                                onMouseMove={move}
                            >
                                <div className={`w-4 audio-player-volume-bar pointer-events-none`} style={{ height: vol + '%' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {playlist ? playlist(assign) : null}
            </div>
        )
    }
)

AudioPlayer.displayName = "AudioPlayer"

export { AudioPlayer }