import * as React from "react"
import { useUserStore } from "shared/stores/useUserStore"
import { Calendar, CalendarPlus, HeartRate, Home, MeetingBoard, Shield, Umbrella, User, UserList, UserPlus } from "ui/icons"
import { Condition, MenuLink, MenuTitle, Menu as MenuWrapper } from "ui/components"
import { Hestia } from "shared/assets/hestia"
import { useAcl } from "ui"
import { Rights } from "../../configs/acl"
import { UserProfil } from "shared/components"
import { useSocketStore } from "shared/stores/useSocketStore"

interface MenuProps {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export const Menu = ({
    setOpen
}: MenuProps) => {

    const acl = useAcl<typeof Rights>()

    const [
        firstname,
        lastname,
        avatarUrl,
        setLogout,
    ] = useUserStore(state => [
        state.firstname,
        state.lastname,
        state.avatarUrl,
        state.setLogout
    ])

    const iconClass = "w-5 h-5 mr-2 flex-none"

    const close = () => {
        setOpen(false)
    }

    const [socketStatus] = useSocketStore(state => [state.status])

    return (
        <MenuWrapper
            logo={<Hestia className="h-5 fill-white" />}
            setLogout={setLogout}
            onClick={close}
        >
            <UserProfil
                firstname={firstname}
                lastname={lastname}
                avatarUrl={avatarUrl}
                roles={acl.getRoles()}
                socketStatus={socketStatus}
            />
            <div className="menu-app-title">
                Application consultant
            </div>
            <div className="pt-4 px-3 flex flex-col gap-2 overflow-auto scrollbar">
                <MenuLink icon={<Home className={ iconClass } />} url={"/"} onClick={close}>Accueil</MenuLink>
                <Condition test={acl.haveRole('admin')}>
                    <MenuTitle label="Social">
                        <MenuLink icon={<Calendar className={ iconClass } />} url={"/social/agenda"} onClick={close}>Agenda</MenuLink>
                    </MenuTitle>
                </Condition>
                <Condition test={acl.isAllows([Rights.ClientNew, Rights.ClientView])}>
                    <MenuTitle label="Clients">
                        <Condition test={acl.isAllow(Rights.ClientNew)}>
                            <MenuLink icon={<UserPlus className={ iconClass } />} url={"/customer/add"} onClick={close}>Ajouter un client</MenuLink>
                        </Condition>
                        <Condition test={acl.isAllow(Rights.ClientView)}>
                            <MenuLink icon={<UserList className={ iconClass } />} url={"/customer/listing"} onClick={close}>Listing des clients</MenuLink>
                        </Condition>
                    </MenuTitle>
                </Condition>
                <Condition test={acl.isAllows([Rights.HeathInsuranceView, Rights.LppView, Rights.LifeInsuranceView])}>
                    <MenuTitle label="Produits">
                        <Condition test={acl.isAllow(Rights.HeathInsuranceView)}>
                            <MenuLink icon={<HeartRate className={ iconClass } />} url={"/insurance/health/listing"} onClick={close}>Assurance maladie</MenuLink>
                        </Condition>
                        <Condition test={acl.isAllow(Rights.LifeInsuranceView)}>
                            <MenuLink icon={<Umbrella className={ iconClass } />} url={"/insurance/life/listing"} onClick={close}>Assurance vie</MenuLink>
                        </Condition>
                        <Condition test={acl.isAllow(Rights.WeekvestView)}>
                            <MenuLink icon={<Home className={ iconClass } />} url={"/weekvest/product/listing"} onClick={close}>Weekvest</MenuLink>
                        </Condition>
                        <Condition test={acl.isAllow(Rights.LppView)}>
                            <MenuLink icon={<Shield className={ iconClass } />} url={"/lpp/listing"} onClick={close}>LPP</MenuLink>
                        </Condition>
                        <Condition test={acl.isAllow(Rights.BuilderView)}>
                            <MenuLink icon={<MeetingBoard className={ iconClass } />} url={"/builder"} onClick={close}>Rendez-vous client</MenuLink>
                        </Condition>
                    </MenuTitle>
                </Condition>
                <Condition test={acl.haveRole('admin')}>
                    <MenuTitle label="Ressources humaines">
                        <MenuLink icon={<CalendarPlus className={ iconClass } />} url={"/ressources-humaines/paid-vacation"} onClick={close}>Congés / absences</MenuLink>
                    </MenuTitle>
                </Condition>
            </div>
        </MenuWrapper>
    )
}