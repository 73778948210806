import * as React from "react"
import { getRoleLabel } from "shared/helpers/getLabel"
import { Avatar } from "ui/components"
import { SocketStatusEnum } from "shared/stores/useSocketStore"
import { RelativeElement } from "ui/components/RelativeElement"

type Props = {
    avatarUrl: string,
    firstname: string,
    lastname: string,
    roles: string[]
    socketStatus?: SocketStatusEnum
}

export const UserProfil = ({
    avatarUrl,
    firstname,
    lastname,
    roles,
    socketStatus
}: Props) => {

    const label = roles.map(getRoleLabel).join(', ').replace(/\,$/g, '')

    return (
        <div className="p-4 flex text-sm items-center user-profil">
            <RelativeElement>
                <Avatar
                    src={avatarUrl}
                    className="w-8 h-8 md:w-10 md:h-10 rounded-full mr-3 border-beaver shadow-sm shadow-white dark:shadow-black/30"
                />
                <div className="absolute -bottom-1 right-2.5">
                    <span className="relative flex h-4 w-4">
                        {
                            socketStatus === SocketStatusEnum.Disconnected ? (
                                <>
                                    <span className="inline-flex rounded-full h-4 w-4 bg-red-500 border-[3px] dark:border-color8 border-color-4"></span>
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                </>
                            )
                            :
                                (<span className="inline-flex rounded-full h-4 w-4 bg-green-500 border-[3px] dark:border-color8 border-color3"></span>)
                        }
                    </span>
                </div>
            </RelativeElement>
            <div className="truncate uppercase text-xs">
                {firstname} {lastname}
                <div className="normal-case text-beaver truncate">{label}</div>
            </div>
        </div>
    )
}