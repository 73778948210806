'use client'

import React, { useState, cloneElement } from "react"
import {
    Outlet,
} from "react-router-dom"

import {
    HamburgerMenu,
} from "../../icons"

type Props = {
    menu: React.JSX.Element
    headerClassName?: string
}

export function Layout({
    menu,
    headerClassName,
}: Props) {

    const [open, setOpen] = useState(false)

    if(!headerClassName) {
        headerClassName = 'bg-zinc-900/80 text-white'
    }

    return (
        <div className="w-screen h-screen flex flex-col md:flex-row layout z-0">
            <div className="fixed md:static z-10 w-full md:w-60 h-full flex flex-col pointer-events-none overflow-hidden shadow-lg dark:shadow-black shadow-black/30">
                <div className={ `pointer-events-auto p-2 px-4 md:hidden flex items-center uppercase cursor-pointer ${headerClassName}` } onClick={ () => { setOpen(!open); }}>
                    <HamburgerMenu className="fill-zinc-300 w-8 h-8 mr-4 flex-none" />
                    Menu
                </div>
                <div className={
                    `pointer-events-auto select-none overflow-hidden md:w-60 flex-1 flex dark:bg-color8 bg-color2 shadow-xl shadow-black/30 transition-all md:transition-none duration-200 truncate ${ open ? 'w-80': 'w-0'}` }
                >
                    <div className="flex flex-1">
                        { cloneElement(menu, {setOpen}) }
                    </div>
                </div>
            </div>
            <div className="flex-1 h-full overflow-auto relative z-0 pt-12 md:pt-0 scrollbar">
                <Outlet />
            </div>
        </div>
    )
}