import React from 'react'
import { Svg } from './Svg'

export function SpeakerHigh(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <path d="M218.9,77.1a71.9,71.9,0,0,1,0,101.8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="80" x2="80" y1="88" y2="168"/>
            <path d="M190.6,105.4a31.9,31.9,0,0,1,0,45.2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}
