import React from 'react'
import { Svg } from './Svg'

export const LogOut = (props) => {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <polyline fill="none" points="174 86 216 128 174 170" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="104" x2="216" y1="128" y2="128"/>
            <path d="M104,216H48a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8h56" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}