import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react"
import { ModalHandle, ToastHandle, Toast, Modal, IFile } from "ui"
import { XMark, Svg } from "ui/icons"
import { ReactChildren } from "ui/types"
import { FileDisplay } from "./FileDisplay"
import { FileInfo } from "./FileInfo"

export enum ESideInfoType {
    None,
    Left,
    Right
}

interface FileViewerProps {
    sidebar?: ESideInfoType
    sidebarTop?: ReactChildren
    sidebarBottom?: ReactChildren
}

interface FileViewerHandle {
    view: (file: IFile) => void,
}

export function FileViewerComponent(
    {
        sidebar = ESideInfoType.None,
        sidebarTop,
        sidebarBottom,
    }: FileViewerProps,
    ref: ForwardedRef<FileViewerHandle>
) {

    const modal = useRef<ModalHandle>(null)
    const refToast = useRef<ToastHandle>(null)
    const [file, setFile] = useState<IFile>()

    useImperativeHandle(ref, () => ({
        view: (file: IFile) => {
            modal.current?.open()
            setFile(file)
        }
    }))

    const close = () => {
        modal.current?.close()
        setFile(undefined)
    }

    const contentByType = () => {
        switch(file?.extension) {
            case 'pdf':
                return <embed src={file.content}  type="application/pdf" className="h-full w-full"></embed>
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'webp':
            case 'svg':
            case 'bmp':
            case 'tiff':
                return <img src={file.content} className="max-h-full maw-w-full" />
        }
    }

    return (
        <>
            <Toast ref={refToast} />
            <Modal className="flex-col lg:w-[95%] h-[95%] w-full" ref={modal}>
                <div className="bg-color9 text-white p-4 flex">
                    <div className="flex-1 text-ellipsis whitespace-nowrap overflow-hidden">
                        {file?.name ?? "Fichier"}
                    </div>
                    <div onClick={close}>
                        <XMark className="w-5 h-5" />
                    </div>
                </div>
                <div className="flex-1 flex">
                    <div className="flex-1 flex items-center justify-center overflow-auto">
                        {
                            file ?
                                contentByType()
                            :
                                <Svg viewBox="0 0 24 24" className={'animate-spin h-20 w-20'}>
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </Svg>
                        }
                    </div>
                    <FileDisplay file={file} />
                    {
                        sidebar ?
                            <div className="w-80 flex flex-col gap-4 p-4 bg-color7/20 shadow-xl shadow-black/20">
                                {sidebarTop}
                                <FileInfo file={file} />
                                {sidebarBottom}
                            </div>
                        : null
                    }
                </div>
            </Modal>
        </>
    )
}

const FileViewer = forwardRef<FileViewerHandle, FileViewerProps>(FileViewerComponent)
FileViewer.displayName = "FileViewer"

export { FileViewer }