import React from 'react'
import { Svg } from './Svg'

export function ChevronLeft(props) {
    return (
        <Svg {...props}>
            <path d="M15.75 19.5L8.25 12l7.5-7.5" />
        </Svg>
    )
}
