import React, { useState } from "react"
import { ReactChildren } from "../../types"
import { CircleUserGear, LogOut } from "../../icons"
import { NavLink } from "react-router-dom"

export interface MenuProps {
    setLogout: () => void
    onClick: () => void
    children?: ReactChildren
    logo?: ReactChildren
}

export function Menu({
    setLogout,
    onClick,
    children,
    logo,
}: MenuProps) {

    const [label, setLabel] = useState<string>()
    const [open, setOpen] = useState(false)

    const toggle = (label?: string) => {
        if(label) {
            setOpen(true)
            setLabel(label)
        } else {
            setOpen(false)
        }
    }

    return (
        <div className="flex flex-col w-full overflow-hidden menu">
            <div className="flex-1 flex flex-col overflow-hidden">
                {
                    logo ?
                        <div className="hidden md:flex menu-logo">{logo}</div>
                    : null
                }
                <div className="flex-1 overflow-hidden flex flex-col">
                    { children }
                </div>
            </div>
            <div className="flex relative menu-footer">
                <div className="absolute bottom-full left-0 w-full px-2 pb-1">
                    <div className={"bg-color9 py-1 px-4 rounded-xl w-full duration-200 transition-all " + (open ? "opacity-100" : "opacity-0")}>{ label }</div>
                </div>
                <NavLink
                    className="cursor-pointer"
                    to="/profil" title="Profil"
                    onMouseEnter={() => toggle('Profil') }
                    onMouseLeave={() => toggle()}
                    onClick={onClick}
                >
                    <CircleUserGear className="w-7 h-7" />
                </NavLink>
                <div className="mx-auto" />
                <a
                    className="cursor-pointer"
                    onClick={ setLogout } title="Déconnexion"
                    onMouseEnter={() => toggle('Déconnexion') }
                    onMouseLeave={() => toggle()}
                >
                    <LogOut className="w-7 h-7" />
                </a>
            </div>
        </div>
    )
}