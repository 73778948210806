import React from 'react'
import { Svg } from './Svg'

export function Pause(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <rect fill="none" height="176" rx="8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" width="52" x="156" y="40"/>
            <rect fill="none" height="176" rx="8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" width="52" x="48" y="40"/>
        </Svg>
    )
}

// Phosphor+user

