import React from 'react'
import { Svg } from './Svg'

export function Umbrella(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <path d="M176,200a24,24,0,0,1-48,0V136" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <path d="M32.3,136a7.9,7.9,0,0,1-7.9-8.7,104,104,0,0,1,207.2,0,7.9,7.9,0,0,1-7.9,8.7Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}