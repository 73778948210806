import React from "react"

export function RelativeElement({
    children,
    className = ''
}): React.JSX.Element {
    const props = {
        className: 'relative'
    }
    if (className) {
        props.className += ` ${  className}`
    }

    return <div {...props}>{children}</div>
}
