import React from 'react'
import { Svg } from './Svg'

export function CircleUserGear({...props}): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <circle cx="128" cy="120" fill="none" r="40" strokeMiterlimit="10" strokeWidth="16"/>
            <path d="M63.8,199.4a72,72,0,0,1,128.4,0" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <circle cx="200" cy="56" fill="none" r="16" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="200" x2="200" y1="40" y2="28"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="186.1" x2="175.8" y1="48" y2="42"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="186.1" x2="175.8" y1="64" y2="70"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="200" x2="200" y1="72" y2="84"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="213.9" x2="224.2" y1="64" y2="70"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="213.9" x2="224.2" y1="48" y2="42"/>
            <path d="M223.3,116.5A87.7,87.7,0,0,1,224,128a96,96,0,1,1-96-96,87,87,0,0,1,8.9.4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}
