export function addClassIfExists(className: string): string {
    return className ? ' ' + className : ''
}

export function isFunction(fn: Function|unknown): boolean {
    return typeof fn === 'function'
}

export function isBoolean(v: unknown): boolean {
    return v === false || v === true
}

export function formatSize(size: number, unit = ''): string {
    const units = ['o', 'ko', 'mo', 'go', 'to']
    let i = units.findIndex((u) => u === unit)

    if (i === -1) {
        i = Math.max(0, Math.floor(Math.log10(size || 1) / 3))
    }

    const sizeFormatted = (size / 1000 ** i).toFixed(i === 0 ? 0 : 2)
    const unitFormatted = units[i] || ''

    return `${sizeFormatted} ${unitFormatted}`
}

export const simpleHash = (str: string): string => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash &= hash // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36)
}

export const keyBuilder = (arr: any[], prefix = '') => {
    prefix = prefix ? `${prefix  }-` : ''
    return arr.map((item) => {
        const endLine = new Date().getTime() + Math.random() + ''
        if (Array.isArray(item) || isObject(item)) {
            return prefix + simpleHash(JSON.stringify(item) + endLine)
        }

        return prefix + simpleHash(item + endLine)
    })
}

export const formatCurrency = (value: number, { locale = 'fr-FR', currency = 'EUR', digits = 0 } = {}): string => {
    return Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: digits
    })
        .format(value)
        .replace(/\s/g, ' ')
}


export function uniqId(): string {
    return (new Date().getTime() + Math.floor(Math.random() * 10000 + 1)).toString(16)
}


export function downloadFile(base64DataOrUri: HTMLHyperlinkElementUtils["href"], fileName: string): void {
    const downloadLink = document.createElement("a")
    downloadLink.href = base64DataOrUri
    downloadLink.target = '_BLANK'
    downloadLink.download = fileName
    downloadLink.click()
    downloadLink.remove()
}