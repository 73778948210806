import React from "react"
import type { ReactChildren } from "../types"

interface SvgProps {
    children?: ReactChildren,
    viewBox?: string
    fill?: string
    stroke?: string
    strokeWidth?: number
    className?: string
    title?: string | null
}

export function Svg({
    children,
    viewBox = '0 0 24 24',
    fill = 'none',
    stroke = 'currentColor',
    strokeWidth = 0,
    className = '',
    title = null
}: SvgProps): React.JSX.Element {
    const props:Partial<SvgProps> = {
        fill,
        viewBox,
        strokeWidth,
        stroke
    }

    if (className) {
        props.className = className
    }

    if (title) {
        props.title = title
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props}>
            {children}
        </svg>
    )
}
