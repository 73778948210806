import type { ForwardedRef} from "react";
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react"
import type { ReactChildren } from "../../types";
import type { ModalHandle } from "./Modal";
import { Modal } from "./Modal"

export interface ConfirmProps {
    title?: string | ReactChildren,
    children?: ReactChildren
    valid?: () => boolean
    cancel?: () => void
    close?: () => void
    open?: boolean
    className?: string
    classNameContent?: string
    modal?: React.RefObject<ModalHandle>
    textConfirm?: string | ReactChildren
    classConfirm?: string
    textCancel?: string | ReactChildren
    overflow?: boolean
}

export interface ConfirmHandle {
    close: () => void
    open: () => void
    toggle: () => void
}

export function ConfirmComponent({
    title,
    children,
    valid,
    cancel,
    close,
    open = false,
    className = "w-96 h-80",
    classNameContent = '',
    textConfirm = "Valider",
    classConfirm = "",
    textCancel = "Annuler",
    overflow = false,
}: ConfirmProps,
ref: ForwardedRef<ConfirmHandle>): React.JSX.Element {

    const modal = useRef<ModalHandle>(null)

    const cancelHandle = useCallback((): void => {
        if(cancel) cancel()
        if(close) close()
        modal.current?.close()
    }, [modal.current, cancel])

    const validHandle = useCallback((): void => {
        let ok = true
        if(valid) ok = valid()
        if(ok) modal.current?.close()
    }, [modal.current, valid])

    useImperativeHandle(ref, () => ({
        close: () => {
            if(close) close()
            modal.current?.close()
        },
        open: () => {
            modal.current?.open()
        },
        toggle: () => {
            modal.current?.toggle()
        }
    }))

    const onClose = () => {
        if(close) close()
    }

    return (
        <Modal
            className={`${className} flex-col cursor-default`}
            open={open}
            ref={modal}
            onClose={onClose}
        >
            { title ? <h2 className="px-4 py-3 bg-tyrianPurple flex text-white">{ title }</h2> : null }
            <div className={`flex-1 p-4${ classNameContent ? ` ${classNameContent}`: '' }` + (overflow ? ' overflow-auto': '')}>
                { children }
            </div>
            <div className="p-2 gap-2 flex justify-end dark:bg-color7 bg-color6">
                <button className="px-4 py-2 confirm-cancel-button" onClick={ cancelHandle } type="button">{textCancel}</button>
                <button className={ "px-4 py-2 " + ( classConfirm ? `confirm-button ${classConfirm}` : 'confirm-valid-button' ) } onClick={ validHandle } type="button">{textConfirm}</button>
            </div>
        </Modal>
    )
}

const Confirm = forwardRef<ConfirmHandle, ConfirmProps>(ConfirmComponent)
Confirm.displayName = "Confirm"

export { Confirm }