import type { ForwardedRef} from "react";
import type React from "react"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { uniqId } from "../../libs"
import { Portal } from "../Portal";

export enum ToastDirectionEnum {
    NorthWest = 'nw',
    NorthEast = 'ne',
    SouthEast = 'se',
    SouthWest = 'sw',
}

export enum ToastStatusEnum {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Neutral = 'neutral',
}

export interface ToastMessage {
    message: string
    status: ToastStatusEnum,
    id: string
    remover: (action: (next:() => void) => void) => NodeJS.Timeout
}

export interface ToastProps {
    direction?: ToastDirectionEnum,
}

export interface ToastHandle {
    add: (message: string, status?: ToastStatusEnum, duration?: number) => void
    clear: () => void
    destroy: () => void
}

function getDirectionClassName(direction: ToastDirectionEnum): string {
    switch (direction) {
        case ToastDirectionEnum.NorthWest:
            return 'top-0 left-0'
        case ToastDirectionEnum.NorthEast:
            return 'top-0 right-0'
        case ToastDirectionEnum.SouthEast:
            return 'bottom-0 right-0'
        case ToastDirectionEnum.SouthWest:
            return 'bottom-0 left-0'
    }
}

function getStatusClassName(status: ToastStatusEnum): string {
    switch (status) {
        case ToastStatusEnum.Success:
            return 'toast-status-success'
        case ToastStatusEnum.Error:
            return 'toast-status-error'
        case ToastStatusEnum.Warning:
            return 'toast-status-warning'
        case ToastStatusEnum.Neutral:
            return 'toast-status-neutral'
    }
}

function ToastItem({
    id,
    message,
    status,
    remover,
}: ToastMessage): React.JSX.Element {

    const [classname, setClassname] = useState('toast-open')

    useEffect(() => {
        const id = remover((next) => {
            setClassname('toast-close')
            setTimeout(() => {
                next()
            }, 500)
        })

        return () => {
            if(id) clearTimeout(id)
        }
    }, [remover])

    return (
        <div className={`toast-item flex ${classname}`} key={id}>
            <div className={`${getStatusClassName(status)} toast-status`} />
            <div className="toast-message">{ message }</div>
        </div>
    )
}

function ToastComponent({
    direction = ToastDirectionEnum.SouthEast
}: ToastProps,
ref: ForwardedRef<ToastHandle> ): React.JSX.Element {

    const directionClass = getDirectionClassName(direction)
    const [element, setElement] = useState<ToastMessage[]>([])

    useImperativeHandle(
        ref,
        () => ({
            add: (message: string, status: ToastStatusEnum = ToastStatusEnum.Neutral, duration = 2000) => {
                const id = uniqId()

                const remover = (callback?: (action: () => void) => void): NodeJS.Timeout => {
                    return setTimeout(() => {
                        const action = (): void => {
                            setElement(stack => {
                                return stack.filter(e => e.id !== id)
                            })
                        }

                        if(callback) {
                            callback(action)
                        } else {
                            action()
                        }

                    }, duration)
                }

                setElement(s => {

                    s.push({ message, status, id, remover})
                    return s
                })
            },
            clear: () => {
                setElement([])
            },
            destroy: () => {
                //document.body.removeChild(rootElement)
            },
            element
        }),
        [element]
    )

    if(typeof element[0]?.message === 'object') {
        // if is native error
        console.error('element', element)
        return null
    }

    return (
        <Portal visible={element?.length > 0}>
            <div className={`${directionClass} absolute toast overflow-hidden max-h-screen pointer-events-none`}>
                {
                    element.map(elm => <ToastItem key={ elm.id } { ...elm } />)
                }
            </div>
        </Portal>
    )
}

const Toast = forwardRef<ToastHandle, ToastProps>(ToastComponent)
Toast.displayName = "Toast"

export { Toast }