import { Lcfirst } from "ui/libs"
import type { AttachmentInterface } from "../stores/useContractStore"
import { useInitStore } from "../stores/useInitStore"
import {
    AttachmentTypeHealthInsurance,
    AttachmentTypeLpp,
    TAttachmentTypeHealthInsurance,
    TAttachmentTypeLpp
} from "../types/AttachmentType"

type TAttachmentsTypes = TAttachmentTypeHealthInsurance | TAttachmentTypeLpp

export function getDocumentLabel(type: TAttachmentsTypes, short = false): string {

    const proposition = (short ? '': "Proposition ")

    switch (type) {
        case AttachmentTypeHealthInsurance.InsurancePolicy:
            return "Police d'assurance"
        case AttachmentTypeHealthInsurance.Lamal:
            return `${proposition}LAMAL`
        case AttachmentTypeHealthInsurance.LamalLca:
            return `${proposition}LAMAL et LCA`
        case AttachmentTypeHealthInsurance.Lca:
            return `${proposition}LCA`
        case AttachmentTypeHealthInsurance.TerminationLetter:
            return "Lettre de résiliation"
        case AttachmentTypeHealthInsurance.Lsa45:
            return "LSA45"
        case AttachmentTypeHealthInsurance.DualAffiliate:
            return "Lettre double affiliation"
        case AttachmentTypeLpp.FreeMovement:
            return "Lettre libre passage"
        case AttachmentTypeLpp.PensionLpp:
            return "Contrat LPP"
        case AttachmentTypeLpp.Proxy:
            return "Procuration"
        case AttachmentTypeLpp.BenefitStatement:
            return "Lettre décompte de prestations"
        case AttachmentTypeLpp.Signature:
            return "Signature"
        case AttachmentTypeLpp.IdentityDocument:
        case AttachmentTypeHealthInsurance.IdentityDocument:
            return "Pièces d'identités"
        case AttachmentTypeLpp.Other:
        case AttachmentTypeHealthInsurance.Other:
            return "Autres documents"
        default:
            return `Inconnu: ${type}`
    }
}

export function getAttachmentLabel(attachment: AttachmentInterface) {

    const getSocietyLabel = useInitStore.getState().getSocietyLabel

    switch(attachment.type) {
        case AttachmentTypeHealthInsurance.Lamal:
        case AttachmentTypeHealthInsurance.Lca:
        case AttachmentTypeHealthInsurance.LamalLca:
            return getDocumentLabel(attachment.type, true) + ' ' + getSocietyLabel(attachment.society)
        case AttachmentTypeHealthInsurance.IdentityDocument:
        case AttachmentTypeHealthInsurance.InsurancePolicy:
        case AttachmentTypeHealthInsurance.TerminationLetter:
        case AttachmentTypeHealthInsurance.Lsa45:
        case AttachmentTypeHealthInsurance.Other:
        default:
            return getDocumentLabel(attachment.type)
    }
}

export function getRoleLabel(role: string): string {
    switch (role.toLowerCase()) {
        case 'admin': return "Administrateur"
        case 'advisor': return "Consultant"
        case 'quality': return "Quality"
        case 'validator': return "Backoffice"
        case 'manager': return "Manager"
        case 'weekvestadmin': return "Admin Weekvest"
        case 'weekvestdirector': return "Directeur Weekvest"
        case 'weekvestproductowner': return "Gestionnaire produits Weekvest"
        case 'weekvestmanager': return "Manager Weekvest"
        default: return role
    }
}