import React from "react"

type Props = {
    src: string,
    className: string,
}

export const Avatar = ({
    src,
    className = 'w-10 h-10 rounded-full',
}: Props) => {

    return (
        <img
            src={src}
            alt=""
            className={`flex-none object-cover ${className}`}
        />
    )
}
