import React from 'react'
import { Svg } from './Svg'

export function UserList(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <circle cx="80" cy="104" fill="none" r="40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="152" x2="248" y1="80" y2="80"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="152" x2="248" y1="128" y2="128"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="176" x2="248" y1="176" y2="176"/>
            <path d="M18,192a64,64,0,0,1,124,0" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}
