export const objectsEqual = (o1: any, o2: any): boolean =>  typeof o1 === 'object' && Object.keys(o1).length > 0 ? Object.keys(o1).length === Object.keys(o2).length  && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p])) : o1 === o2;

export function isObject(obj: object): boolean {
    return typeof obj === 'object'
}

export function getIndexOfItem<T>(source: T[], testFunction: (obj: T) => boolean): number {
    return source.findIndex(item => testFunction(item))
}

export function getIndexOfItemExists<T>(source: T[], testFunction: (obj: T) => boolean): boolean {
    return getIndexOfItem(source, testFunction) !== -1
}

export function getIndexOfItemIid<T, R>(source: T[], ref: R) : number {
    return source.findIndex(i => i.iid === ref.iid)
}

export function deepClone(obj) {
    if (typeof obj !== "object" || obj === null) {
        return obj
    }

    if(Array.isArray(obj)) {
        const clone = [...obj]
        clone.map((e) => deepClone(e))
        return clone
    }

    const clone = {}
    for (const key in obj) {
        clone[key] = deepClone(obj[key])
    }
    return clone
}

export function mutableObject(object: Record<string, unknown>) {

    const nObjet: Record<string, unknown> = {}
    return {
        append: (afterKey: keyof object, key: string, value: unknown, replace = false) => {

            if(object[key] && !replace) {
                return object
            }

            for (const k in object) {
                nObjet[k] = object[k]
                if (k === afterKey) {
                    nObjet[key] = value
                }
            }

            return nObjet
        },
        prepend: (beforeKey: keyof object, key: string, value: unknown, replace = false) => {

            if(object[key] && !replace) {
                return object
            }

            for (const k in object) {
                if (k === beforeKey) {
                    nObjet[key] = value
                }
                nObjet[k] = object[k]
            }

            return nObjet
        }
    }
}