import React from 'react'
import { Svg } from './Svg'

export function ChevronDown(props) {
    return (
        <Svg {...props}>
            <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </Svg>
    )
}
