import * as React from "react"

type Props = {

}

export const NotFoundPage = ({

}: Props) => {
    return (
        <>
            <h1>Error 404!</h1>
            <p>Page introuvable</p>
        </>
    )
}
