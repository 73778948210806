import type { ChangeEvent } from "react"
import type { InputEventType } from "../types"
import { isString } from "ui/libs/string"
import { isBoolean } from "../libs"

interface UseInputStateClassType {
    errored: boolean
    modified: boolean
    focus: boolean
    disabled: boolean
    readonly: boolean
    required: boolean
}

export interface useUniqEventInterface {
    value: any
    native: boolean
    original: any
    data: any
}

export const useInputStateClass = (
    {
        errored = false,
        modified = false,
        focus = false,
        disabled = false,
        readonly = false,
        required = false
    }: UseInputStateClassType,
    className = ''
): string => {
    let classState = ''

    if (errored) {
        classState = ' state-errored'
    } else if (modified) {
        classState = ' state-modified'
    } else if (focus) {
        classState = ' state-focus'
    } else {
        classState = ' state-normal'
    }

    if (disabled) {
        classState += ' state-disabled'
    }

    if (readonly) {
        classState += ' state-readonly'
    }

    if (required) {
        classState += ' state-required'
    }

    if (!readonly && !disabled) {
        classState += ' cursor-pointer'
    }

    return classState + (className ? ` ${className}` : '')
}

export const syntheticEvent = (e: string | ChangeEvent<HTMLInputElement>, data: any = {}): useUniqEventInterface  => {
    const is: boolean = isString(e) || isBoolean(e)
    return {
        value: is ? e : e.target.value,
        native: !is,
        original: is ? {} : e,
        data: data
    }
}

export interface UseInputType {
    value?: string,
    mask?: string
    className?: string
    onUpdate?: (data: InputEventType) => useUniqEventInterface
    props?: {
        disabled?: boolean
        readonly?: boolean
    }
}

export const useInput = ({
    onUpdate,
    className = '',
    mask,
    ...props
}: UseInputType) => {

    let imask: RegExp | null = null
    if(mask) {
        imask = new RegExp(mask, 'g')
    }

    const trigger = (newValue: string | ChangeEvent<HTMLInputElement>, data?: any): void => {
        const se = syntheticEvent(newValue, data)
        //const valid = imask ? se.value.match(imask) : true
        if(onUpdate) onUpdate(se)
    }

    return {
        trigger,
        classState: useInputStateClass(props, className),
        isModifiable: !props?.disabled && !props.readonly
    }
}
