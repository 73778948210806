import { useState } from 'react'
import Api from '../libs/api'
import { downloadFile } from '../libs'
import { useUserStore } from 'shared/stores/useUserStore'

type  useDownloadFileReturn = [
    (idOrData: string, label?: string, token?: string) => void,
    boolean
]

export const useDownloadFile = (uri: string): useDownloadFileReturn => {
    const [loading, setLoading] = useState(false)

    const download = (idOrData: string, label?: string): void => {
        if(!idOrData) {
            throw new Error('No id or data provided')
        }

        if(!label) {
            label = 'fichier.jpg'
        }

        setLoading(true)
        if(idOrData.startsWith('data:')) {
            downloadFile(idOrData, label)
            setLoading(false)
        } else {
            useDownloadBase64(uri, idOrData)
                .then((data) => {
                    downloadFile(data, label)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return [
        download,
        loading
    ]
}

type useDisplayFileReturn = [
    (uri: string) => Promise<string>,
    boolean
]
export const useDisplayFile = (): useDisplayFileReturn => {
    const api = new Api()
    const [loading, setLoading] = useState(false)

    const download = (uri: string) => {
        setLoading(true)
        const token = useUserStore.getState().token
        return api
            .setToken(token)
            .getBase64(uri)
            .finally(() => {
                setLoading(false)
            })
    }

    return [download, loading]
}

export const useDownloadBase64 = (uri: string, id: string) => {
    const token = useUserStore.getState().token
    const api = new Api(token)
    return api
        .getBase64(uri.replace(/%id%/g, id))
}

export const useDownloadRaw = (uri: string, id: string) => {
    const token = useUserStore.getState().token
    const api = new Api(token)
    return api
        .getRaw(uri.replace(/%id%/g, id))
}

type  useDownloadPdf = [
    (url: string) => Promise<String>,
    boolean
]

export const usePdfDownload = (): useDownloadPdf => {
    const [loading, setLoading] = useState(false)

    const download = (url: string): Promise<string> => {
        setLoading(true)
        const headers = new Headers()
        const token = useUserStore.getState().token
        headers.set('Authorization', `Bearer ${token}`)

        return fetch(url, { headers })
            .then((response) => {
                return response.blob()
            }).then((blob) => {
                setLoading(false)
                return URL.createObjectURL(blob)
            })
    }

    return [
        download,
        loading
    ]
}