import React, { useEffect, useState } from "react"
import { IFile } from "ui/components/File/TypeFile"
import { Svg } from "ui/icons"
import { useDisplayFile } from "../../hooks"

interface FileDisplayProp {
    file?: IFile
}
export function FileDisplay({
    file
}: FileDisplayProp) {

    const [content, setContent] = useState<any>()
    const [download] = useDisplayFile()

    const contentByType = (content?: string, extension?: string) => {
        switch(extension) {
            case 'pdf':
                return <embed src={content}  type="application/pdf" className="h-full w-full min-h-96"></embed>
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'webp':
            case 'svg':
            case 'bmp':
            case 'tiff':
                return <img src={content} className="max-h-full max-w-full" />
        }
    }

    useEffect(() => {
        if(!file?.content && file?._id) {
            download(`document/file/${file._id}/display`).then((base64: string) => {
                setContent(contentByType(base64, file?.ext))
            })
        } else if(file?.content && file?.extension) {
            setContent(contentByType(file.content, file?.extension))
        }
    }, [file])

    return (
        <div className="flex-1 flex items-center justify-center overflow-auto">
            {
                content ?
                    content
                :
                    <Svg viewBox="0 0 24 24" className={'animate-spin h-20 w-20'}>
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </Svg>
            }
        </div>
    )
}