import { useCallback, useEffect, useRef } from 'react'

export const useEnterLeaveTimer = (callback: () => void, timer = 2000) => {
    const to = useRef<NodeJS.Timeout | null>(null)

    const clearTimer = useCallback(() => {
        if (to.current) {
            clearTimeout(to.current)
        }
    }, [])

    useEffect(() => {
        return () => {
            clearTimer()
        }
    }, [clearTimer])

    const onLeave = (): void => {
        to.current = setTimeout(() => {
            callback()
        }, timer)
    }

    const onEnter = (): void => {
        clearTimer()
    }

    return {
        onLeave,
        onEnter,
        clearTimer
    }
}
