import React from 'react'
import { Svg } from './Svg'

export function ChevronUp(props) {
    return (
        <Svg {...props}>
            <path d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </Svg>
    )
}
