import React, { useState, forwardRef, useImperativeHandle } from 'react'
import type { ReactChildren } from '../../types'

export interface FloatingPanelHandle {
    toggle: () => void
    isOpen: () => void
}

export enum FloatingPanelPositionInterface {
    N = 'N',
    NE = 'NE',
    NW = 'NW',
    S = 'S',
    W = 'W',
    E = 'E',
}

export interface FloatingPanelProps {
    position: FloatingPanelPositionInterface,
    content,
    children?: ReactChildren
    element,
    open?: boolean,
    className?: string
}


export function FloatingPanelComponent({
    position,
    content,
    children,
    element,
    open = false,
    className = ''
}: FloatingPanelProps, ref): React.JSX.Element {
    let location = []
    const [iOpen, setIOpen] = useState(open)
    const size = {
        width: element?.current?.clientWidth || 0,
        height: element?.current?.clientHeight || 0
    }

    switch (position.toUpperCase()) {
        case FloatingPanelPositionInterface.E:
            location = {
                bottom: size.height,
                left: 0,
                width: '100%'
            }
            break
        case FloatingPanelPositionInterface.NE:
            break
        case FloatingPanelPositionInterface.NW:
            break
        case FloatingPanelPositionInterface.S:
            location = {
                top: size.height,
                left: 0,
                width: '100%'
            }
            break
        case FloatingPanelPositionInterface.W:
            break
        case FloatingPanelPositionInterface.N:
            location = {
                top: 0,
                left: size.width
            }
            break
    }

    useImperativeHandle(ref, () => ({
        toggle: () => {
            setIOpen(!iOpen)
            return !iOpen
        },
        isOpen: () => {
            return iOpen
        }
    }))

    return (
        <div className="relative">
            {
                iOpen ? (
                    <div
                        className={`absolute z-10 ${ className ? ` ${className}` : ''}`}
                        style={location}
                    >
                        {content}
                    </div>
                ) : null
            }
            { children }
        </div>
    )
}


const FloatingPanel = forwardRef<FloatingPanelHandle, FloatingPanelProps>(FloatingPanelComponent)
FloatingPanel.displayName = "FloatingPanel"

export { FloatingPanel }