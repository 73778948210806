import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react"
import { ModalHandle, Modal } from "../Modal"
import { Svg, XMark } from "../../icons"
import { usePdfDownload } from "../../hooks"
import { Toast, ToastHandle } from "../Toast"

interface PdfViewerProps {
    label?: string
}

interface PdfViewerHandle {
    view: (url: string) => void,
    setLabel: (label: string) => void
}

export function PdfViewerComponent({
    label: _label
}: PdfViewerProps,
ref: ForwardedRef<PdfViewerHandle>) {

    const modal = useRef<ModalHandle>(null)
    const refToast = useRef<ToastHandle>(null)
    const [blob, setBlob] = useState<string>('')
    const [label, setLabel] = useState(_label)
    const [downloadPdf, loading] = usePdfDownload()

    useImperativeHandle(ref, () => ({
        view: (url: string) => {
            modal.current?.open()
            downloadPdf(url)
                .then((b) => {
                    setBlob(b)
                })
                .catch()
        },
        setLabel: (label: string) => {
            setLabel(label)
        }
    }))

    const close = () => {
        modal.current?.close()
        setBlob('')
    }

    return (
        <>
            <Toast ref={refToast} />
            <Modal className="flex-col lg:w-[95%] h-[95%] w-full" ref={modal}>
                <div className="bg-color9 text-white p-4 flex">
                    <div className="flex-1">
                        {label}
                    </div>
                    <div onClick={close}>
                        <XMark className="w-5 h-5" />
                    </div>
                </div>
                <div className={"flex-1 " + (loading ? 'flex items-center justify-center' : 'overflow-auto')}>
                    {
                        blob ?
                            <embed src={blob}  type="application/pdf" width="100%" height="100%"></embed>
                        :
                            <Svg viewBox="0 0 24 24" className={'animate-spin h-20 w-20'}>
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </Svg>
                    }
                </div>
            </Modal>
        </>
    )
}

const PdfViewer = forwardRef<PdfViewerHandle, PdfViewerProps>(PdfViewerComponent)
PdfViewer.displayName = "PdfViewer"

export { PdfViewer }