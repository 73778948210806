import React from 'react'
import { Svg } from './Svg'

export function UserPlus(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="200" x2="248" y1="136" y2="136"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="224" x2="224" y1="112" y2="160"/>
            <circle cx="108" cy="100" fill="none" r="60" strokeMiterlimit="10" strokeWidth="16"/>
            <path d="M22.2,200a112,112,0,0,1,171.6,0" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}
