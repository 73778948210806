import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { XMark } from "../../icons"
import { ESidepanel, SidepanelHandle, SidepanelProp } from "./TypeSidepanel"

export function SidepanelComponent(
    {
        position = ESidepanel.Right,
        open: _open = false,
        label = '',
        children,
    }: SidepanelProp,
    ref: ForwardedRef<SidepanelHandle>
) {

    let classes = position === ESidepanel.Right ?  'right-0' : 'left-0'
    const [open, setOpen] = useState(_open)

    useEffect(() => {
        setOpen(_open)
    }, [_open])

    classes += open ? ' translate-x-0' : ' translate-x-96'

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!open)
        }
    }))


    return (
        <div className={`h-[calc(100%-48px)] md:h-full w-96 duration-300 transition-all fixed top-[48px] md:top-0 sidepanel ${classes}`}>
            <div className="flex p-4 items-center">
                <div className="flex-1 text-ellipsis whitespace-nowrap overflow-hidden">{label}</div>
                <div className="cursor-pointer" onClick={() => setOpen(false)}><XMark className="w-5 h-5" /></div>
            </div>
            <div className="flex-1 flex flex-col overflow-auto scrollbar">
                {children}
            </div>
        </div>
    )
}

const Sidepanel = forwardRef<SidepanelHandle, SidepanelProp>(SidepanelComponent)
Sidepanel.displayName = "Sidepanel"

export { Sidepanel }