import { FileInterface } from 'ui/hooks/useInputFile'
import { uniqId, arraysEqual, isBoolean } from 'ui/libs'
import { create } from 'zustand'
import { TAttachmentTypeHealthInsurance, AttachmentTypeHealthInsurance } from '../types/AttachmentType'

export interface AttachmentInterface {
    id?: string
    iid?: string
    type: TAttachmentTypeHealthInsurance
    society?: string
    files?: FileInterface[]
    delete?: boolean
}

export interface ContractUserInterface {
    id: string
    personId?: string
    iid: string
    firstname: string
    lastname: string
    attachments: AttachmentInterface[]
    dateCreated?: string
    delete?: boolean
    status?: string
    amount?: number
    signedDate?: string
    newCustomer?: boolean
    sick?: boolean
    package?: boolean
    isOldContractCancelByCustomer?: boolean
}

export type ManagerUsersType = Record<string, Record<string, {
    lastname: string,
    firstname: string,
    email: string
}>>

export interface ContractStoreType {
    consultantId: string,
    contracts: ContractUserInterface[]
    comment: string,
    phone: string,
    email: string,
    timeSlot: number[],
    audio: FileInterface[],
    note: number,
    initial: {
        comment: string,
        phone: string,
        email: string,
        timeSlot: number[],
        contracts: ContractUserInterface[],
        consultantId: string,
    },
    managers: ManagerUsersType,
    assignAudio: (data: FileInterface[]) => void
    removeAudio: (id: string) => void
    assignManagers: (data: ManagerUsersType) => void
    updatePerson: (field: string, value: string, iid: string) => void
    assignPersons: (data: ContractUserInterface[]) => void
    addPerson: (data: Partial<ContractUserInterface>) => void
    addEmptyPerson: () => void
    removePerson: (iid: string) => void
    copyPerson: (iid: string) => void
    addAttachment: (iid: string, data: AttachmentInterface) => void
    addAttachments: (iid: string, aiid: string, data: AttachmentInterface[]) => void
    removeAttachment: (iid: string, aiid: string, fiid: string) => void
    removeAttachments: (iid: string, aiid: string) => void
    getList: (iid: string) => TAttachmentTypeHealthInsurance[],
    isValid: () => boolean
    reset:() => void
    setTimeSlot: (slots: number[], initial?: boolean) => void
    setComment: (comment: string, initial?: boolean) => void
    setPhone: (phone: string, initial?: boolean) => void
    setEmail: (email: string, initial?: boolean) => void
    setConsultantId: (consultantId: string, initial?: boolean) => void
    isModified: () => boolean
    assignNote: (note: number) => void
    addAudio: (data: FileInterface) => void
    setAmount: (iid: string, amount: number) => void
    setSignedDate: (iid: string, date: string) => void
    setNewCustomer: (iid: string, value: boolean) => void
    setSick: (iid: string, value: boolean) => void
    setPackage: (iid: string, value: boolean) => void
    getData: () => void
    apply: () => void
}

const getDataType = (data: any) => [
    Array.isArray(data),
    typeof data === 'object'
]

const copyByType = (data: any) => {
    const [isArray, isObject] = getDataType(data)
    return [
        isArray ? [...data] : isObject ? {...data} : data,
        isArray,
        isObject
    ]
}

const copyDeep = (data: any) => {
    let [cp, isArray, isObject] = copyByType(data)

    if(isArray) {
        cp = cp.map((entry: any) => copyDeep(entry))
    } else if(isObject) {
        Object.keys(cp).forEach( k => {
            cp[k] = copyDeep(cp[k])
        })
    }

    return cp
}

const cleanId = (data: any) => {
    let [isArray, isObject] = getDataType(data)

    if(isArray) {
        data = data.map((entry: any) => cleanId(entry))
    } else if(isObject) {
        Object.keys(data).forEach( k => {
            if(k === 'id') {
                data[k] = ''
            } else if (k === 'iid') {
                data[k] = uniqId()
            } else {
                data[k] = cleanId(data[k])
            }
        })
    }

    return data
}

const newPerson = (): ContractUserInterface => {
    return {
            id: '',
            iid: uniqId(),
            firstname: '',
            lastname: '',
            isOldContractCancelByCustomer: false,
            attachments: [],
            personId: '',
        }
}

const findIndex = (contracts: ContractUserInterface[], iid: string): number => {
    return contracts.findIndex((e) => e.iid === iid)
}

export const useContractStore = create<ContractStoreType>((set, get) => ({
    consultantId: '',
    contracts: [],
    comment: '',
    phone: '',
    email: '',
    timeSlot: [],
    audio: [],
    note: 0,
    initial: {
        comment: '',
        phone: '',
        email: '',
        timeSlot: [],
        contracts: [],
        consultantId: '',
    },
    managers: {},
    assignNote: (note) => set((state) => {
        return { ...state, note: note }
    }),
    setTimeSlot: (slots, initial = false) => set((state) => {
        const initialState = {...state.initial}
        if(initial) {
            initialState.timeSlot = slots
        }
        return { ...state, timeSlot: slots, initial: initialState }
    }),
    setComment: (comment, initial = false) => set((state) => {
        const initialState = {...state.initial}
        if(initial) {
            initialState.comment = comment
        }
        console.trace('COMMENT', comment, initialState, initial)
        return { ...state, comment: comment, initial: initialState }
    }),
    setPhone: (phone, initial = false) => set((state) => {
        const initialState = {...state.initial}
        if(initial) {
            initialState.phone = phone
        }
        return { ...state, phone: phone, initial: initialState }
    }),
    setEmail: (email, initial = false) => set((state) => {
        const initialState = {...state.initial}
        if(initial) {
            initialState.email = email
        }
        return { ...state, email: email, initial: initialState }
    }),
    setConsultantId: (consultantId, initial = false) => set((state) => {
        const initialState = {...state.initial}
        if(initial) {
            initialState.consultantId = consultantId
        }

        return { ...state, consultantId: consultantId, initial: initialState }
    }),
    assignManagers: (managers) => set((state) => {
        return { ...state, managers: managers }
    }),
    updatePerson: (field: string, value: string, iid: string) => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)
        if(index!== -1) {
            c[index][field] = value
        }
        return { ...state, contracts: c }
    }),
    assignPersons: (data) => set((state) => {
        const copy = JSON.parse(JSON.stringify(data))
        return {
            ...state,
            contracts: data,
            initial: {
                contracts: copy,
                timeSlot: state?.initial?.timeSlot ?? [],
                comment: state?.initial?.comment ?? '',
                consultantId: state?.initial?.consultantId ?? '',
                phone: state?.initial?.phone ?? '(+__) _ __ __ __ __',
                email: state?.initial?.email ?? '',
            }
        }
    }),
    addPerson: (data) => set((state) => {
        return { ...state, ...data }
    }),
    addEmptyPerson: () => set((state) => {
        const c = state.contracts
        c.push(newPerson())
        return { ...state }
    }),
    removePerson: (iid: string) => set((state) => {
        let c = state.contracts
        if(c.length === 1) {
            c[0] = newPerson()
        } else {
            c.splice(findIndex(c, iid), 1)
        }
        return { ...state }
    }),
    copyPerson: (iid) => set((state) => {
        const c = copyDeep([...state.contracts])
        const index = findIndex(state.contracts, iid)
        const copy = copyDeep(c[index])
        const cp = cleanId({...copy})
        c.push(cp)
        return { ...state, contracts: c }
    }),
    addAttachment: (iid: string, data: AttachmentInterface) => set((state) => {
        data.iid = uniqId()
        const c = state.contracts
        const index = findIndex(c, iid)

        const index2 = c[index].attachments.findIndex((e: { type: TAttachmentTypeHealthInsurance }) => {
            return e.type === data.type
        })

        // lamal already exists ? push all files
        if(c[index].attachments[index2]) {
            c[index].attachments[index2].files.push(...data.files)
            c[index].attachments[index2].delete = false
        } else {
            c[index].attachments.push(data)
        }

        return { ...state }
    }),
    addAttachments: (iid: string, aiid: string, data: AttachmentInterface[]) => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)
        const subIndex = findIndex(c[index].attachments, aiid)

        data.forEach(d => {
            d.iid = uniqId()
            c[index].attachments[subIndex].files.push(d)
        })

        return { ...state}
    }),
    removeAttachment: (iid: string, aiid: string, fiid: string) => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)
        const subIndex = findIndex(c[index].attachments, aiid)
        const subSubIndex = findIndex(c[index].attachments[subIndex].files, fiid)
        if(index > -1 && subIndex > -1 && subSubIndex > -1) {
            if(c[index].attachments[subIndex].files[subSubIndex].content) {
                // remove attachments not uploaded
                c[index].attachments[subIndex].files.splice(subSubIndex, 1)
            } else {
                // remove attachments uploaded
                c[index].attachments[subIndex].files[subSubIndex].delete = true
            }
        }

        // check if all files are deleted
        const remove = c[index].attachments[subIndex].files.reduce((a: boolean, b: { hasOwnProperty: (arg0: string) => any; delete: boolean }) => {
            if(!b.hasOwnProperty('delete') || b.delete === false) {
                return false
            }
            return a
        }, true)

        if(remove) {
            c[index].attachments[subIndex].delete = true
        }

        return { ...state }
    }),
    removeAttachments: (iid: string, aiid: string) => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)
        const subIndex = findIndex(c[index].attachments, aiid)
        if(index > -1 && subIndex > -1) {

            c[index].attachments[subIndex].files = c[index].attachments[subIndex].files
                .filter((f: { content: any }) => {
                    return !f.content
                })
                .map((f: { delete: boolean }) => {
                    f.delete = true
                    return f
                })

            c[index].attachments[subIndex].delete = true
            if(c[index].attachments[subIndex].files?.length === 0) {
                c[index].attachments.splice(subIndex,1)
            }
        }

        return { ...state }
    }),
    getList: (iid: string) => {
        const contracts = get().contracts
        const index = findIndex(contracts, iid)
        let LamalLca = false
        let LamalLcaInverted = false
        let list = contracts[index].attachments
            .filter(c => !c.hasOwnProperty('delete') || c.delete === false)
            .map(contract => {
                if(AttachmentTypeHealthInsurance.Lamal === contract.type || AttachmentTypeHealthInsurance.Lca === contract.type) {
                    LamalLca = true
                } else if(AttachmentTypeHealthInsurance.LamalLca === contract.type) {
                    LamalLcaInverted = true
                }
                return contract.type
            })

        if(LamalLca) {
            list.push(AttachmentTypeHealthInsurance.LamalLca)
        } else if(LamalLcaInverted) {
            list.push(AttachmentTypeHealthInsurance.Lamal, AttachmentTypeHealthInsurance.Lca)
        }

        return list
    },
    isValid: () => {
        return true
    },
    reset: () => set((state) => {
        return {
            ...state,
            contracts: [],
            comment: '',
            timeSlot: [],
            managers: {},
            email: '',
            phone: '',
            consultantId: '',
            initial: {
                comment: "",
                consultantId: "",
                contracts: [],
                email: "",
                phone: "",
                timeSlot: []
            }
        }
    }),
    isModified: () => {
        const {
            contracts,
            initial,
            comment,
            timeSlot,
            consultantId,
            email,
            phone
        } = get()

        let isModified = false

        function checkKey(index: number, c: ContractUserInterface, key: keyof ContractUserInterface) {
            return (
                !initial?.contracts[index] &&
                c[key]
            )
                ||
            (
                initial?.contracts[index] &&
                initial?.contracts[index][key] &&
                initial?.contracts[index][key] !== c[key]
            )
        }

        function checkIfIsDelete(c: any) {
            return (!!c.id && c.delete === true) || (!c.id && c.delete === false)
        }

        contracts.forEach((c, index) => {

            // firstname must be equal and exists
            if(checkKey(index, c, 'firstname')) {
                isModified = true
            }

            // lastname must be equal and exists
            if(checkKey(index, c, 'lastname')) {
                isModified = true
            }


            if(checkIfIsDelete(c)) {
                isModified = true
            }

            c.attachments.forEach(a => {
                if(checkIfIsDelete(a)) {
                    isModified = true
                }

                a.files.forEach(f => {
                    if(checkIfIsDelete(f) || !!f.content) {
                        isModified = true
                    }
                })
            })

            // is Old Contract Cancel By Customer is different ?
            if(
                isBoolean(c.isOldContractCancelByCustomer) &&
                initial?.contracts[index] &&
                initial?.contracts[index]?.isOldContractCancelByCustomer !== c.isOldContractCancelByCustomer
            ) {
                isModified = true
            }

            if(initial?.contracts[index]?.amount !== c.amount) {
                isModified = true
            }

            if(initial?.contracts[index]?.signedDate !== c.signedDate) {
                isModified = true
            }

            if(initial?.contracts[index]?.newCustomer !== c.newCustomer) {
                isModified = true
            }

            if(initial?.contracts[index]?.sick !== c.sick) {
                isModified = true
            }

            if(initial?.contracts[index]?.package !== c.package) {
                isModified = true
            }

        })

        if(comment !== initial.comment) {
            isModified = true
        }

        if( (consultantId || initial.consultantId) && consultantId !== initial.consultantId) {
            isModified = true
        }

        if((email || initial.email) && email !== initial.email) {
            isModified = true
        }

        if((phone || initial.phone) && phone !== initial.phone) {
            isModified = true
        }

        if((timeSlot || initial.timeSlot) && !arraysEqual(timeSlot, initial.timeSlot)) {
            isModified = true
        }

        return isModified
    },
    assignAudio: (data) => set((state) => {
        return { ...state, audio: data }
    }),
    addAudio: (data) => set((state) => {
        const audios = [...state.audio]
        audios.push(data)
        return { ...state, audio: audios }
    }),
    removeAudio: (id) => set((state) => {
        return { ...state, audio: state.audio.filter(a => id !== a.docId) }
    }),
    setAmount: (iid: string, amount: number)  => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)

        if(index !== -1) {
            c[index].amount = amount
        }

        return { ...state}
    }),
    setSignedDate: (iid: string, date: string)  => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)

        if(index !== -1) {
            c[index].signedDate = date
        }

        return { ...state}
    }),
    setNewCustomer: (iid: string, value: boolean) => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)

        if(index !== -1) {
            c[index].newCustomer = value
        }

        return { ...state}
    }),
    setSick: (iid: string, value: boolean) => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)

        if(index !== -1) {
            c[index].sick = value
        }

        return { ...state}
    }),
    setPackage: (iid: string, value: boolean) => set((state) => {
        const c = state.contracts
        const index = findIndex(c, iid)

        if(index !== -1) {
            c[index].package = value
        }

        return { ...state}
    }),
    apply: () => set((state) => {
        const copy = JSON.parse(JSON.stringify(state))
        return {
            ...state,
            initial: {
                comment: copy.comment,
                phone: copy.phone,
                email: copy.email,
                timeSlot: copy.timeSlot,
                contracts: copy.contracts,
                consultantId: copy.consultantId,
            }
        }
    }),
    getData: () => {
        const data = get()
        return {
            consultantId: data.consultantId,
            contracts: data.contracts,
            comment: data.comment,
            phone: data.phone,
            email: data.email,
            timeSlot: data.timeSlot,
            audio: data.audio,
            note: data.note,
        }
    }
}))