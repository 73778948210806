import React from "react"
import { dateFormatter, formatSize } from "ui"
import { IFile } from "ui/components/File/TypeFile"

interface FileInfoProp {
    file?: IFile
}

export function FileInfo({
    file
}: FileInfoProp) {

    let lastModified = '--/--/----'
    if(file?.lastModified) {
        lastModified = dateFormatter(new Date(file.lastModified))
    } else if(file?.dateUpdated) {
        lastModified = dateFormatter(new Date(file.dateUpdated))
    }

    let extension = 'ND'
    if(file?.extension) {
        extension = file.extension
    } else if(file?.ext) {
        extension = file.ext
    }

    return (
        <div className="flex flex-col gap-2 text-sm">
            <div className="flex">
                <div className="w-28 text-white">Extension</div>
                <div>{extension}</div>
            </div>
            <div className="flex">
                <div className="w-28 text-white">Taille</div>
                <div>{file?.size ? formatSize(file.size) : 'ND'}</div>
            </div>
            <div className="flex">
                <div className="w-28 text-white">Modification</div>
                <div>{lastModified}</div>
            </div>
        </div>
    )
}