import React, { useState, useEffect, useMemo, memo } from 'react'
import { useEnterLeaveTimer, useInput, useBoolean } from '../../../hooks'
import { ChevronLeft, ChevronRight, XMark } from '../../../icons'

function formatNumber(n: null | number, ln = 2): string {
    if (n === null || isNaN(n)) return ''
    return ('0'.repeat(ln - 1) + n).slice(-1 * ln)
}

interface DateType {
    year: number | null
    month: number | null
    day: number | null
    hour: number | null
    minute: number |  null
    second: number |  null
    millisecond: number |  null
}

function dateToObject(format: string, date: string): DateType {
    const reg = buildRegexp(format)
    const match = reg.exec(date || '')
    let response: DateType = {
        year: null,
        month: null,
        day: null,
        hour: null,
        minute: null,
        second: null,
        millisecond: null
    }
    if (match) {
        response = { ...match.groups }
        Object.keys(response).forEach((k) => {
            if (k === 'shortyear') {
                response['year'] = parseInt(`20${ response[k] }`, 10)
            } else {
                response[k] = parseInt(response[k], 10)
            }
        })
    }

    return {
        year: isNaN(response.year) ? 0 : response.year,
        month: response.month,
        day: response.day,
        hour: response.hour,
        minute: response.minute,
        second: response.second,
        millisecond: response.millisecond
    }
}

function buildRegexp(date: string): RegExp {
    const reg = date
        .replace(/yyyy/g, '(?<year>\\d{4})?')
        .replace(/mmm/g, '(?<millisecond>\\d{3})?')
        .replace(/MM/g, '(?<month>\\d{2})?')
        .replace(/dd/g, '(?<day>\\d{2})?')
        .replace(/hh/g, '(?<hour>\\d{2})?')
        .replace(/mm/g, '(?<minute>\\d{2})?')
        .replace(/ss/g, '(?<second>\\d{2})?')
        .replace(/yy/g, '(?<shortyear>\\d{2})?')
    return new RegExp(reg, 'g')
}

function FormatDisplay({ data, display, setType, type, setOpen, isModifiable }) {
    const sp = display.split(/(\:|\-|\/|\s|\.)/g)
    const classNameBase =
        ` duration-200 transition-all datetime-zone flex items-center justify-center h-8${  isModifiable ? ' cursor-pointer' : ''}`
    return sp.map((segment, i) => {
        let className = 'datetime-separator'
        let section = null
        switch (segment) {
            case 'yyyy':
                segment = data.year ? formatNumber(data.year, 4) : ''
                className = `datetime-year w-12${ classNameBase }`
                section = 'year'
                break
            case 'mmm':
                segment = formatNumber(data.millisecond, 3)
                className = `datetime-millisecond w-12${ classNameBase }`
                section = 'millisecond'
                break
            case 'MM':
                segment = data.month ? formatNumber(data.month) : ''
                className = `datetime-month w-8${ classNameBase }`
                section = 'month'
                break
            case 'dd':
                segment = data.day ? formatNumber(data.day) : ''
                className = `datetime-day w-8${ classNameBase }`
                section = 'day'
                break
            case 'hh':
                segment = formatNumber(data.hour)
                className = `datetime-hour w-8${ classNameBase }`
                section = 'hour'
                break
            case 'mm':
                segment = formatNumber(data.minute)
                className = `datetime-minute w-8${ classNameBase }`
                section = 'minute'
                break
            case 'ss':
                segment = formatNumber(data.second)
                className = `datetime-second w-8${ classNameBase }`
                section = 'second'
                break
            case ' ':
                className = 'datetime-space'
            default:
                className += ' datetime-separator h-8 mb-[2px] pb-[2px] flex items-center justify-center'
                break
        }

        if (section === type) {
            className += ' state-focus'
        }

        const event =
            isModifiable && section
                ? (e) => {
                    e.stopPropagation()
                    setOpen()
                    setType(section)
                }
                : () => {}
        return (
            <span className={className} key={`datetime-zone-${section}-${i}`} onClick={event}>
                {segment}
            </span>
        )
    })
}

const BuildNumber = memo(({
    callback,
    current,
    type,
    number = 60,
    start = 0,
    step = 1,
    length = 2
}) => {
    const className =
        'py-1 px-2 rounded-lg flex items-center justify-center datetime-item select-none transition-all duration-200 cursor-pointer'

    start -= step
    const tmp = [...Array(number / step).keys()].map(() => (start += step))

    return tmp.map((v) => {
        let select = ''
        if (current === v) {
            select = ' datetime-current'
        }
        return (
            <div
                className={className + select}
                key={`${type}-${v}`}
                onClick={() => {
                    callback(v)
                }}
            >
                {formatNumber(v, length)}
            </div>
        )
    })
})

const BuildMonth = memo(({
    callback,
    current
}: {callback: (value: number) => void, current: number | null}) => {
    const className =
        'py-1 px-2 rounded-lg flex items-center justify-center datetime-item select-none transition-all duration-200 cursor-pointer'
    const months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ]
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((v) => {
        let select = ''
        if (current === v) {
            select = ' datetime-current'
        }
        return (
            <div
                className={className + select}
                key={`month-${v}`}
                onClick={() => {
                    callback(v)
                }}
            >
                {months[v - 1]}
            </div>
        )
    })
})

function buildYear(callback, current) {
    const className =
        'rounded-lg flex items-center justify-center datetime-item select-none transition-all duration-200 cursor-pointer'
    let start = current - 4
    let content = [...Array(9).keys()]
        .map(() => start++)
        .map((v) => {
            let select = ''
            if (current === v) {
                select = ' datetime-current'
            }
            return (
                <div
                    className={`py-1 px-2 ${className}${select}`}
                    key={`year-${v}`}
                    onClick={() => {
                        callback(v)
                    }}
                >
                    {v}
                </div>
            )
        })

    content.unshift(
        <div
            className={`p-1 ${className}`}
            key={`previous-year`}
            onClick={() => {
                callback(current - 9)
            }}
        >
            <ChevronLeft className="w-4 h-4 datetime-chevron" />
        </div>
    )
    content.push(
        <div
            className={`p-1 ${className}`}
            key={`next-year`}
            onClick={() => {
                callback(start + 4)
            }}
        >
            <ChevronRight className="w-4 h-4 datetime-chevron" />
        </div>
    )

    return content
}

function formatOut(value: DateType, format: string) {
    return format
        .replace(/yyyy/g, value.year || '')
        .replace(/mmm/g, formatNumber(value.millisecond, 3) || '000')
        .replace(/MM/g, formatNumber(value.month) || '00')
        .replace(/dd/g, formatNumber(value.day) || '00')
        .replace(/hh/g, formatNumber(value.hour) || '00')
        .replace(/mm/g, formatNumber(value.minute) || '00')
        .replace(/ss/g, formatNumber(value.second) || '00')
        .replace(/yy/g, parseInt((`${value.year}`).substring(2, 2), 10))
}

function lastDayOfMonth(y, m) {
    return m === 2 ? (y & 3 || (!(y % 25) && y & 15) ? 28 : 29) : 30 + ((m + (m >> 3)) & 1)
}

export interface DateTimeProps {
    value?: string
    modified?: boolean
    disabled?: boolean
    readonly?: boolean
    cycle?: number
    display?: string
    format?: string
    steps?: {
        year?: number
        month?: number
        day?: number
        hour?: number
        minute?: number
        second?: number
        millisecond?: number
    },
    onUpdate: (value: string) => void
}


export function Datetime({
    value = '',
    modified = false,
    disabled = false,
    readonly = false,
    cycle = 24,
    display = 'dd/MM/yyyy hh:mm:ss',
    format = 'yyyy-MM-ddThh:mm:ss.mmmZ',
    steps = {
        year: 1,
        month: 1,
        day: 1,
        hour: 1,
        minute: 1,
        second: 10,
        millisecond: 100
    },
    onUpdate = () => {}
}: DateTimeProps) {
    // toggler for open / close panel
    const { state: open, setTrue: setOpen, setFalse: setClose } = useBoolean(false)

    const memoDate = useMemo(() => dateToObject(format, value), [format, value])
    const [data, setData] = useState(memoDate)

    const { trigger, isModifiable, classState } = useInput({
        value,
        onUpdate,
        focus: open,
        modified,
        disabled,
        readonly,
        className:
            'relative inline-block align-middle w-full text-sm datetime-wrapper transition-all duration-5'
    })

    const [type, setType] = useState(null)

    // close panel after leave
    const { onLeave, onEnter } = useEnterLeaveTimer(() => {
        setClose()
        setType(null)
    })

    useEffect(() => {
        if (value) {
            setData(dateToObject(format, value))
        }
    }, [value])

    const updateElement = (field, currentValue) => {
        setData((previous) => {
            let current = {}
            current[field] = currentValue
            const nvalue = { ...previous, ...current }
            trigger(formatOut(nvalue, format))
            setClose()
            return nvalue
        })
    }

    const className =
        'absolute bg-secondary b-primary max-h-32 overflow-auto p-2 flex flex-wrap gap-1 text-xs transition-all duration-300 z-[1] datetime'
    const lastDay = lastDayOfMonth(data.year, data.month)

    return (
        <div
            className={classState}
            onMouseLeave={() => (open ? onLeave() : null)}
            onMouseEnter={() => (open ? onEnter() : null)}
        >
            <div
                className="flex flex-row text-center select-none items-center"
                onClick={() => {
                    setClose()
                    setType(null)
                }}
            >
                {FormatDisplay({
                    data,
                    display,
                    type,
                    setType,
                    setOpen,
                    isModifiable
                })}
                {open ? (
                    <>
                        <div className="m-auto"></div>
                        <div>
                            <XMark className="datetime-icon-close w-5 h-5" />
                        </div>
                    </>
                ) : null}
            </div>
            {isModifiable ? (
                <div
                    className={
                        className +
                        (open ? ' datetime-open' : ' datetime-close pointer-events-none') +
                        (modified ? ' state-modified' : '')
                    }
                >
                    {
                        type === 'hour'?
                            <BuildNumber
                                callback={(value) => {
                                    updateElement('hour', value)
                                }}
                                current={data.hour}
                                type="hour"
                                number={cycle - 1}
                                start={1}
                                length={2}
                            />
                        : null
                    }
                    {
                        type === 'minute' ?
                            <BuildNumber
                                callback={(value) => {
                                    updateElement('minute', value)
                                }}
                                current={data.minute}
                                type="minute"
                                step={steps.minute}
                            />
                        : null
                    }
                    {
                        type === 'second' ?
                            <BuildNumber
                                callback={(value) => {
                                    updateElement('second', value)
                                }}
                                current={data.second}
                                type="second"
                                step={steps.second}
                            />
                        : null
                    }
                    {
                        type === 'month' ?
                            <BuildMonth
                                callback={(value) => {
                                    updateElement('month', value)
                                }}
                                current={data.month}
                            />
                        :
                            null
                    }
                    {
                        type === 'day' ?
                            <BuildNumber
                                callback={(value) => {
                                    updateElement('day', value)
                                }}
                                current={data.day}
                                type="day"
                                number={lastDay}
                                start={1}
                                step={steps.day}
                            />
                        : null
                    }
                    {
                        type === 'millisecond' ?
                            <BuildNumber
                                callback={(value) => {
                                    updateElement('millisecond', value)
                                }}
                                current={data.millisecond}
                                type="millisecond"
                                number={1000}
                                start={1}
                                step={steps.millisecond}
                                length={3}
                            />
                        : null
                    }
                    {
                        type === 'year'
                        ? buildYear((value) => {
                            updateElement('year', value)
                        }, data.year || new Date().getFullYear()) : null
                    }
                </div>
            ) : null}
        </div>
    )
}

