import React from "react"
import { ReactChildren } from "../../types"

interface ConditionProps {
    test: boolean
    children: ReactChildren
}

export function Condition({
    test,
    children
}: ConditionProps) {

    return (
        <>{test ? children : null}</>
    )
}