import { createPortal } from "react-dom";
import type { ReactChildren } from "../../types";

interface PortalProps {
    visible: boolean
    children? : ReactChildren
}

export function Portal({
    visible,
    children
}: PortalProps) {
    return visible ? createPortal(children, document.body) : null
}