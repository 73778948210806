import React from 'react'
import { Svg } from './Svg'

export const Home = (props) => {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <path d="M152,208V160a8,8,0,0,0-8-8H112a8,8,0,0,0-8,8v48a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V115.5a8.3,8.3,0,0,1,2.6-5.9l80-72.7a8,8,0,0,1,10.8,0l80,72.7a8.3,8.3,0,0,1,2.6,5.9V208a8,8,0,0,1-8,8H160A8,8,0,0,1,152,208Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}

// Phosphor

