import type { ForwardedRef} from "react";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import type { ReactChildren } from "../../types"
import { Portal } from "../Portal";


export interface ModalProps {
    children?: ReactChildren
    layout?: boolean
    className?: string
    open?: boolean
    overlayClosable?: boolean
    onClose?: () => void
    onOpen?: () => void
}

export interface ModalHandle {
    close: () => void
    open: () => void
    toggle: () => void
    isOpen: boolean
}

function layoutBuilder(
    layout: boolean,
    overlayCloseHandle: (e: Event) => void,
    modalProps,
    content: ReactChildren,
    overlayClosable: boolean
) {
    if(layout) {
        return (<>
            {
                content ?
                    <div
                        className={`modal-overlay h-screen w-screen flex items-center justify-center flex-1 pointer-events-auto${ overlayClosable ? ' cursor-pointer' : ''}`}
                        onClick={ overlayCloseHandle }
                        onKeyDown={ overlayCloseHandle }
                        role="button"
                        tabIndex={0}
                    >
                        <div {...modalProps}>{ content }</div>
                    </div>
                    : null
            }
            </>
        )
    }
    return <div {...modalProps}>{ content }</div>
}

function ModalContent({open, content}) {
    return (
        <>{ open ? content : null}</>
    )
}

export function ModalComponent({
    layout = true,
    children = '',
    className = '',
    open = false,
    overlayClosable = true,
    onClose = () => {},
    onOpen = () => {},
}: ModalProps,
ref: ForwardedRef<ModalHandle>): React.JSX.Element {

    const [_open, _setOpen] = useState<boolean>(open)
    const [_content, setContent] = useState(children)

    useImperativeHandle(ref, () => ({
        close: () => {
            _setOpen(false)
        },
        open: () => {
            _setOpen(true)
        },
        toggle: () => {
            _setOpen(!open)
        },
        setContent: (content) => {
            setContent(content)
        },
        isOpen: open
    }))

    useEffect(() => {
        setContent(children)
    }, [children])

    const overlayCloseHandle = useCallback((e: Event): void => {
        if(overlayClosable && e.target === e.currentTarget) _setOpen(false)
    }, [overlayClosable])

    const modalProps = {
        role: "alertdialog",
        "aria-modal": true,
        className: `modal pointer-events-auto flex ${className}`
    }

    const content = layoutBuilder(layout, overlayCloseHandle, modalProps, _content, overlayClosable)

    useEffect(() => {
        if(_open) {
            onOpen()
        } else {
            onClose()
        }
    }, [_open])

    return (
        <Portal visible={_open}>
            <div className="modal-wrapper z-[100] h-screen w-screen fixed flex items-center justify-center pointer-events-none top-0 left-0">
                <ModalContent open={_open} content={content} />
            </div>
        </Portal>
    )
}

const Modal = forwardRef<ModalHandle, ModalProps>(ModalComponent)
Modal.displayName = "Modal"

export { Modal }