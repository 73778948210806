import { create } from 'zustand'


export interface CompaniesInterface {
    id: string
    label: string
    isEnabled: boolean
}


interface InitInterface {
    insurance: {
        companies: CompaniesInterface[]
    }
}

interface CompaniesApiInterface {
    _id: string
    label: string
}

interface InitApiInterface {
    insurance: {
        companies: CompaniesApiInterface[]
    }
}

type UserInitType = {
    companies: CompaniesInterface[]
    loaded: boolean
    setInit: (data: InitApiInterface) => void
    getSocietyLabel: (id: string) => string
    getSocietyByLabel: (label: string) => string | null
}

export const useInitStore = create<UserInitType>((set, get) => ({
    companies: [],
    loaded: false,
    setInit: (data: InitApiInterface) => set((state) => {
        const _data = {
            companies: data.insurance.companies.map(c => ({
                id: c._id,
                label: c.label,
                isEnabled: c.isEnabled
            }))
        }

        return { ...state, ..._data, loaded: true }
    }),
    getSocietyLabel: (id: string) => {
        const companies = get().companies
        const index = companies.findIndex(c => c.id === id)
        return index !== -1 && companies[index] ? companies[index].label : 'Inconnu'
    },
    isLoaded:() => {
        return get().loaded
    },
    getSocietyByLabel: (label: string) => {
        const companies = get().companies
        const l = label.toLowerCase()
        const index = companies.findIndex(c => {
            return c.label.toLowerCase().startsWith(l)
        })
        return index !== -1 && companies[index] ? companies[index].id : null
    }
}))