import { create } from 'zustand'
import { getAvatarUrl } from '../helpers/getAvatarUrl'

type UserStoreType = {
    id: string
    avatarUrl: string
    name: string
    firstname: string
    lastname: string
    username: string
    email: string
    token: string
    acl: string[]
    roles: string[]
    keycloak: any,
    setLogin: (data: UserStoreType) => void
    setLogout: () => void
    setToken: (token: string) => void
    setKeycloak: (keycloak: any) => void
    apply401: () => void
}

export const useUserStore = create<UserStoreType>((set, get) => ({
    id: '',
    avatarUrl: '',
    name: '',
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    token: '',
    acl: [],
    roles: [],
    keycloak: null,
    setLogin: (data: UserStoreType) => set((state) => {
        return { ...state, ...data, avatarUrl: getAvatarUrl(data.id) }
    }),
    setLogout: () => set((state) => {
        console.log('LOGOUT')
        state?.keycloak?.logout()
        return {
            id: '',
            avatarUrl: '',
            name: '',
            firstname: '',
            lastname: '',
            username: '',
            email: '',
            token: '',
            acl: [],
            roles: [],
        }
    }),
    apply401: () => {
        const state = get()
        state?.keycloak?.login()
    },
    setToken: (token: string) => set((state) => {
        return {...state, token: token}
    }),
    setKeycloak: (keycloak) => set((state) => {
        return {...state, keycloak: keycloak}
    })
}))