import React, { useEffect, useRef, useState } from "react";
import { ReactChildren } from "../../types"

const VISIBLE = 'VISIBLE'
const HIDDEN = 'HIDDEN'
const ENTERING = 'ENTERING'
const LEAVING = 'LEAVING'

interface TransitionProps {
    appear: boolean
    show: boolean
    destroy?: boolean
    enter: string
    enterFrom: string
    enterTo: string
    leave?: string
    leaveFrom?: string
    leaveTo?: string
    children?: ReactChildren
}

export function Transition({
    appear = false,
    show,
    enter,
    enterFrom,
    enterTo,
    leave,
    leaveFrom,
    leaveTo,
    destroy = true,
    children
}: TransitionProps) {

    const childRef = useRef(children)
    const [state, setState] = useState(
        show ? (appear ? ENTERING : VISIBLE ) : HIDDEN
    )

    if (show) {
        childRef.current = children
    }

    let durationEnter = parseInt((enter.match(/duration\-(\d+)/) || ['', '100'])[1], 10)
    let durationLeave = parseInt((leave?.match(/duration\-(\d+)/) || ['', durationEnter + ''])[1], 10)

    let current = ''
    switch(state) {
        case VISIBLE:
            current = `${enter} ${enterTo}`
            break
        case ENTERING:
            current = `${enter} ${enterFrom}`
            break
        case HIDDEN:
            current = `${leave ?? enter} ${leaveFrom ?? enterTo}`
            break
        case LEAVING:
            current = `${leave ?? enter} ${leaveTo ?? enterTo}`
            break
    }

    useEffect(() => {
        if (!show) {
            setState(LEAVING)
        } else {
            setState((s) => (s === HIDDEN ? ENTERING : VISIBLE))
        }
    }, [show, appear])

    useEffect(() => {
        document.body.offsetHeight
        if (state === LEAVING) {
            const timer = setTimeout(() => {
                setState(HIDDEN)
            }, durationLeave)

            return () => {
                clearTimeout(timer)
            }
        } else if (state === ENTERING) {
            const timer = setTimeout(() => {
                setState(VISIBLE)
            }, 0)

            return () => {
                clearTimeout(timer)
            }
        }
    }, [state])

    if (state === HIDDEN && destroy) {
        return null
    }

    return (
        <div className={current}>
            {childRef.current}
        </div>
    )
}