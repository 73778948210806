import { create } from "zustand"

export enum SocketStatusEnum {
    Connected = 'connected',
    Disconnected = 'disconnected'
}

type SocketStoreType = {
    status: SocketStatusEnum
    setStatus: (status: SocketStatusEnum) => void
}

export const useSocketStore = create<SocketStoreType>((set) => ({
    status: SocketStatusEnum.Disconnected,
    setStatus: (status: SocketStatusEnum) => set((state) => {
        return { ...state, status }
    }),
}))