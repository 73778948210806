import React from 'react'
import { Svg } from './Svg'

export function Stop(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <rect fill="none" height="152" rx="6.9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" width="152" x="52" y="52"/>
        </Svg>
    )
}

// Phosphor+user

