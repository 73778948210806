import React, { ForwardedRef, MutableRefObject, forwardRef } from "react"
import { ReactChildren } from "../../types"

interface TooltipProps {
    children: ReactChildren
    className?: string
    ref: MutableRefObject<HTMLDivElement | undefined>
    group?: string
}

export const Tooltip = forwardRef((
    {
        children,
        className,
        group = ""
    }: TooltipProps,
    ref: ForwardedRef<HTMLDivElement>
) => {
    switch(group) {
        case "tooltip":
            group = "group-hover/tooltip:scale-100"
            break
        default:
            group = "group-hover:scale-100"
            break
    }


    return (
        <div className={`absolute duration-100 transition-all scale-0 ${group}${className ? ` ${className}` : ''} tooltip-wrapper`} ref={ref}>
            {children}
        </div>
    )
})