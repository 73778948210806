import Api from 'ui/libs/api'
import { useUserStore } from 'shared/stores/useUserStore'
import { useInitStore } from 'shared/stores/useInitStore'

export function initService() {
    const token = useUserStore.getState().token
    const api = new Api(token)

    // advisor/init
    api.get("advisor/app/init")
        .then((response) => {
            useInitStore.getState().setInit(response.data)
        })
        .catch(err => {
            console.error(err)
        })
}