import React from "react"
import { ReactChildren } from "ui/types"

type Props = {
    label: string,
    children: ReactChildren,
}

export const MenuTitle = ({
    label,
    children,
}: Props) => {

    return (
        <div className="menu-title-wrapper">
            <div className="menu-title-label hidden md:block">{label}</div>
            <div className="overflow-hidden flex flex-col gap-1 menu-title-content">
                {children}
            </div>
        </div>
    )
}