import { create } from "zustand"

interface usePersistantType {
    persistant: Record<string, any>
    setPersistant: (key:string, data: any) => void
    removePersistant: (key:string, field: string) => void
    getPersistant: (key: string) => any
    mergePersistant: (key:string, data: any) => Record<string, any>
}

export const usePersistantStore = create<usePersistantType>((set, get) => ({
    persistant: {},
    getPersistant: (key: string) => {
        const persistant = get().persistant
        return persistant[key] ?? {}
    },
    setPersistant: (key:string, data: any) => set((state) => {
        let persist: Record<string, any> = {}

        if(state.persistant[key]) {
            persist[key] = { ...state.persistant[key], ...data}
        } else {
            persist[key] = data
        }

        return { ...state, persistant: {...persist} }
    }),
    removePersistant: (key:string, field: string) => set((state) => {
        let persist = {...state}

        if(key && field && persist.persistant[key] && persist.persistant[key][field]) {
            delete persist.persistant[key][field]
        } else if(key && persist.persistant[key]) {
            delete persist.persistant[key]
        }

        return persist
    }),
    mergePersistant: (key:string, data: any) => {
        const state = get().persistant
        const persist = state[key] ?? {}

        Object.keys(data).forEach(k => {
            persist[k] = data[k]
        })

        console.log('MERGE', get(), state, persist)

        return persist
    },
}))