import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { ReactChildren } from "../types";

interface CropDefinition {
    x: number
    y: number
    width: number
    height: number
    px: number
    py: number
    pw: number
    ph: number
}

interface CropDragDefinition {
    x: number
    y: number
    crop: CropDefinition
}

interface CropInitialDefinition {
    width: number
    height: number
    ratio: number
    widthO: number
    heightO: number
}

interface CallbackCrop {
    cropper: ReactChildren,
    cropped: string,
    save: () => void,
    image: React.RefObject<HTMLImageElement>
}

export interface CropOptions {
    ratio?: number
    width?: number
    height?: number
    mimeType?: string
    flipHorizontal?: boolean
    flipVertical?: boolean
}

const computedPercent = (data: CropDefinition, width: number, height: number) => {
    const d = {...data}
    d.px = d.x / width
    d.py = d.y / height
    d.pw = d.width / width
    d.ph = d.height / height
    return d
}

const positionFix = (data: CropDefinition, initial: CropInitialDefinition, ratio?: number, reference = 'width' ) => {
    const d = {...data}

    if(d.width < 20) {
        d.width = 20
    }

    if(d.height < 20) {
        d.height = 20
    }

    if(ratio && reference === 'width') {
        d.height = d.width * ratio
    } else if(ratio && reference === 'height') {
        d.width = d.height * ratio
    }

    if(d.x < 0) d.x = 0
    if(d.y < 0) d.y = 0
    if(d.x + d.width > initial.width) d.x = initial.width - d.width
    if(d.y + d.height > initial.height) d.y = initial.height - d.height

    d.px = d.x / initial.width
    d.py = d.y / initial.height
    d.pw = d.width / initial.width
    d.ph = d.height / initial.height

    return computedPercent(d, initial.width, initial.height)
}


export function useImageCropper(
    src: string | undefined,
    onCrop: (image: string) => void,
    callback: (cropData: CallbackCrop) => any,
    options: CropOptions
) {

    const {
        ratio,
        mimeType = 'image/jpeg',
        flipHorizontal,
        flipVertical
    } = options

    const [image, setImage] = useState<string|undefined>(src)
    const [croppedImage, setCroppedImage] = useState('')
    const [crop, setCrop] = useState<CropDefinition>({ x: 0, y: 0, width: 0, height: 0, px: 0, py: 0, pw: 0, ph: 0 })
    const [dragStart, setDragStart] = useState<CropDragDefinition>({ x: 0, y: 0, crop: { x: 0, y: 0, width: 0, height: 0, px: 0, py: 0, pw: 0, ph: 0 } })
    const drag = useRef(false)
    const [initial, setInitial] = useState<CropInitialDefinition>({ widthO: 0, heightO: 0, width: 0, height: 0, ratio: 0})
    const direction = useRef('')
    const resizing = useRef(false)
    const imageRef = useRef<HTMLImageElement>(null)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if(ctx) ctx.imageSmoothingEnabled = true
    const img = new Image()
    if(image) img.src = image

    const drawCroppedImage = () => {
        const {
            width: ww,
            height: hh
        } = options
        const w = ww ? ww : crop.width
        const h = hh ? hh : ratio ? w * ratio : crop.height

        canvas.width = w
        canvas.height = h

        const x = initial.widthO * crop.px
        const y = initial.heightO * crop.py
        const width = initial.widthO * crop.pw
        const height = initial.heightO * crop.ph

        if(flipHorizontal || flipVertical) {
            const fx = flipHorizontal ? -1 : 1
            const fy = flipVertical ? -1 : 1
            ctx?.scale(fx, fy)
            ctx?.translate(flipHorizontal ? -w : 0, flipVertical ? -h : 0)
        }

        ctx?.drawImage(img, x, y, width, height, 0, 0, w, h)

        const croppedDataURL = canvas.toDataURL(mimeType)
        setCroppedImage(croppedDataURL)
    }

    const mouseStart = (e, type: string, dir: string) => {
        //console.log('start')
        setDragStart({
            x: e.clientX,
            y: e.clientY,
            crop: { ...crop }
        })
        drag.current = true
        if (type === 'resize') {
            resizing.current = true
            direction.current = dir
        }
    }

    const moveCancel = () => {
        //console.log('cancel')
        drag.current = false
        resizing.current = false
    }

    const handleMouseMove = (e) => {
        e.preventDefault()
        //console.log('move')

        if(drag.current) {
            let positionX = e.clientX - dragStart.x
            let positionY = e.clientY - dragStart.y

            if (resizing.current) {
                switch(direction.current) {
                    case 'l':
                        assignCrop({
                            ...crop,
                            x: dragStart.crop.x + positionX,
                            y: dragStart.crop.y,
                            width: dragStart.crop.width - positionX,
                            height: dragStart.crop.height
                        })
                        break
                    case 't':
                        assignCrop({
                            ...crop,
                            x: dragStart.crop.x,
                            y: dragStart.crop.y + positionY,
                            width: dragStart.crop.width,
                            height: dragStart.crop.height - positionY
                        }, 'height')
                        break
                    case 'r':
                        assignCrop({
                            ...crop,
                            x: dragStart.crop.x,
                            y: dragStart.crop.y,
                            width: dragStart.crop.width + positionX,
                            height: dragStart.crop.height
                        })
                        break
                    case 'tl':
                        assignCrop({
                            ...crop,
                            x: dragStart.crop.x + positionX,
                            y: dragStart.crop.y,
                            width: dragStart.crop.width - positionX,
                            height: dragStart.crop.height - positionY
                        })
                        break
                    case 'tr':
                        assignCrop({
                            ...crop,
                            x: dragStart.crop.x,
                            y: dragStart.crop.y + positionY,
                            width: dragStart.crop.width + positionX,
                            height: dragStart.crop.height - positionY
                        }, 'height')
                        break
                    case 'br':
                        assignCrop({
                            ...crop,
                            x: dragStart.crop.x,
                            y: dragStart.crop.y,
                            width: dragStart.crop.width + positionX,
                            height: dragStart.crop.height + positionY
                        })
                        break
                    case 'bl':
                        assignCrop({
                            ...crop,
                            x: dragStart.crop.x + positionX,
                            y: dragStart.crop.y,
                            width: dragStart.crop.width - positionX,
                            height: dragStart.crop.height + positionY
                        })
                        break
                    case 'b':
                        assignCrop({
                            ...crop,
                            x: dragStart.crop.x,
                            y: dragStart.crop.y,
                            width: dragStart.crop.width,
                            height: dragStart.crop.height + positionY
                        }, 'height')
                        break
                }

            } else {
                assignCrop({
                    ...crop,
                    x: dragStart.crop.x + positionX,
                    y: dragStart.crop.y + positionY
                })
            }
        }
    }

    const assignCrop = (data: CropDefinition, reference = 'width') => {
        setCrop({...crop, ...positionFix(data, initial, ratio, reference)})
        //drawCroppedImage()
    }

    const handleImageLoad = () => {
        // Set initial width and height based on the image size
        const initialWidth = imageRef.current?.naturalWidth || 0
        const initialHeight = imageRef.current?.naturalHeight || 0
        const r = initialWidth / initialHeight
        const w = imageRef.current?.clientWidth || 0
        const h = imageRef.current?.clientHeight || w / r

        const data = {
            width: w,
            height: h
        }

        setInitial({
            ...data,
            ratio: r,
            widthO: initialWidth,
            heightO: initialHeight,
        })

        //console.log('IMAGE REF', imageRef.current?.width)
        //console.log('INITIAL', initial)
        //console.log('INITIAL CROP', crop)

        const ww = w * .33
        const hh = ratio ? ww * ratio  : h * .33
        const xx = (w - ww) / 2
        const yy = (h - hh) / 2

        const c = computedPercent({
            ...crop,
            width: ww,
            height: hh,
            y: yy,
            x: xx
        }, w, h)

        setCrop(c)
    }

    const handleCropSubmit = () => {
        if(onCrop) onCrop(croppedImage)
    }

    /*
    const clearCanvas = () => {
        ctx?.clearRect(0, 0, canvas.width, canvas.height)
        const croppedDataURL = canvas.toDataURL()
        setCroppedImage(croppedDataURL)
    }
    */

    useEffect(() => {
        drawCroppedImage()
    }, [crop])

    useEffect(() => {
        drawCroppedImage()
    }, [options])

    useEffect(() => {
        setImage(src)
    }, [src])


    return callback({
        cropper: (
            <>
            {
                image ?
                    <div
                        className="relative select-none h-full"
                        onMouseLeave={moveCancel}
                        onMouseUp={moveCancel}
                        onMouseMove={handleMouseMove}
                    >
                        <img
                            ref={imageRef}
                            src={image}
                            className={"h-full pointer-events-none select-none"}
                            onLoad={handleImageLoad}
                        />
                        <div
                            onMouseDown={(e) => mouseStart(e, 'drag', '')}
                            className="absolute cursor-move image-cropper-dragger"
                            style={{
                                left: crop.x,
                                top: crop.y,
                                border: '1px dashed #fff',
                                width: crop.width,
                                height: crop.height,
                            }}
                        >
                            <div className="relative w-full h-full">
                                <Corner
                                    mouseStart={mouseStart}
                                    position='tl'
                                    direction='se-resize'
                                    css={{ left: '-5px', top: '-5px' }}
                                />
                                <Corner
                                    mouseStart={mouseStart}
                                    position='tr'
                                    direction='ne-resize'
                                    css={{ right: '-5px', top: '-5px' }}
                                />
                                <Corner
                                    mouseStart={mouseStart}
                                    position='br'
                                    direction='se-resize'
                                    css={{ right: '-5px', bottom: '-5px', }}
                                />
                                <Corner
                                    mouseStart={mouseStart}
                                    position='bl'
                                    direction='ne-resize'
                                    css={{ left: '-5px', bottom: '-5px' }}
                                />
                                <Corner
                                    mouseStart={mouseStart}
                                    position='l'
                                    direction='ew-resize'
                                    css={{ left: '-5px', bottom: 'calc(50% - 5px)' }}
                                />
                                <Corner
                                    mouseStart={mouseStart}
                                    position='r'
                                    direction='ew-resize'
                                    css={{ right: '-5px', bottom: 'calc(50% - 5px)' }}
                                />
                                <Corner
                                    mouseStart={mouseStart}
                                    position='t'
                                    direction='ns-resize'
                                    css={{ top: '-5px', left: 'calc(50% - 5px)' }}
                                />
                                <Corner
                                    mouseStart={mouseStart}
                                    position='b'
                                    direction='ns-resize'
                                    css={{ bottom: '-5px', left: 'calc(50% - 5px)' }}
                                />
                            </div>
                        </div>
                    </div>
                : null
            }
            </>
        ),
        cropped: croppedImage,
        save: handleCropSubmit,
        image: imageRef
    })
}

interface CornerProps {
    mouseStart: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, action: string, position: string) => void
    position: string
    direction: string
    css: any
}

function Corner({
    mouseStart,
    position,
    direction,
    css
}: CornerProps) {
    return (<div
        onMouseDown={(e) => mouseStart(e, 'resize', position)}
        className="absolute border-white border rounded-full bg-white"
        style={{
            cursor: direction,
            width: '9px',
            height: '9px',
            ...css
        }}
    />)
}