import { io, Manager } from "socket.io-client"

const sockets: Record<string, any> = {}

interface useSocketProps {
    url: string,
    name?: string,
    namespace?: string
}

interface SocketParams {
    auth?: {
        token: string
    }
}

interface SocketEmit {
    data?: unknown,
    callback?: (response:any) => void
}


export const useSocket = ({
    url,
    name = 'main',
    namespace
}: useSocketProps) => {

    function create(token?: string) {
        let socket: any
        const params:SocketParams = {}
        const rooms = {}

        console.log("[SOCKET] ASSIGN TOKEN", token)

        if(token) {
            params.auth = {
                "token": token,
            }
        }

        if(namespace) {
            const manager = new Manager(url)
            socket = manager.socket(`/${namespace}`, params)
        } else {
            socket = io(url, params)
        }

        socket?.on("connect", () => {
            console.log(`[SOCKET] [${name}] connected`)
        })

        socket?.on("disconnect", () => {
            console.log(`[SOCKET] [${name}] disconnect`)
        })

        socket?.on("close", () => {
            console.log(`[SOCKET] [${name}] close`)
        })

        socket?.onAny((event, ...args) => {
            //console.log(`got ${event}`, args)
        })

        socket.io.on("reconnect", () => {
            const r = Object.keys(rooms)
            if(r.length > 0) {
                r.forEach(room => {
                    socket.emit('join', {...rooms[room], room})
                })
            }
        })

        function addRoom(room: string, data: any) {
            if(!rooms[room]) {
                rooms[room] = data
            }
        }

        function removeRoom(room: string) {
            if(rooms[room]) {
                delete rooms[room]
            }
        }

        sockets[name] = {
            emit: (action: string, props: SocketEmit) => {
                const {data = {}, callback = undefined} = props
                if(callback) {
                    console.log('[SOCKET] EMIT', action, data, callback)
                    socket.emit(action, data, (response: any) => {
                        console.log('[SOCKET] EMIT CB', action, response)
                        callback(response)
                    })
                } else {
                    console.log('[SOCKET] EMIT', action, data)
                    socket.emit(action, data)
                }
                return sockets[name]
            },
            on: (action: string, callback: (data: any) => void) => {
                console.log('[SOCKET] ON', action, callback)
                socket.on(action, (response: any) => {
                    console.log('[SOCKET] ON CB', action, response)
                    callback(response)
                })
                return sockets[name]
            },
            off: (action: string, callback: (data: any) => void) => {
                console.log('[SOCKET] OFF', action, callback)
                socket.off(action, (response: any) => {
                    console.log('[SOCKET] OFF CB', action, response)
                    callback(response)
                })
                return sockets[name]
            },
            join: (room: string, data: any = {}) => {
                console.log('[SOCKET] JOIN', room)
                addRoom(room, data)
                socket.emit('join', {...data, room})
                return sockets[name]
            },
            leave: (room: string, data: any = {}) => {
                console.log('[SOCKET] LEAVE', room)
                removeRoom(room)
                socket.emit('leave', {...data, room})
                return sockets[name]
            },
            tokenUpdate(token: string) {
                console.log('[SOCKET] TOKEN UPDATE', token)
                params.auth = {
                    "token": token,
                }
                socket.emit('tokenUpdate', {token})
            }
        }

        return sockets[name]
    }

    return [
        create
    ]
}

export function getSocket(name?: string) {
    return sockets[name || 'main']
}