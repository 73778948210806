import { ReactChildren } from "../../types"

export enum ESidepanel {
    Right,
    Left
}

export interface SidepanelProp {
    position?: ESidepanel
    open?: boolean
    label?: string
    children?: ReactChildren
}

export interface SidepanelHandle {
    open: () => void
    close: () => void
    toggle: () => void
}