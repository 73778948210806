import React from 'react'
import { Svg } from './Svg'

export function Calendar(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <rect fill="none" height="176" rx="8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" width="176" x="40" y="40"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="176" x2="176" y1="24" y2="56"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="80" x2="80" y1="24" y2="56"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="40" x2="216" y1="88" y2="88"/>
            <path d="M92,128h28l-16,20a16,16,0,1,1-11.3,27.3" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <polyline fill="none" points="144 140 160 128 160 180" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}
