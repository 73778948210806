import React, {
    useCallback,
    forwardRef,
    useImperativeHandle,
    useEffect,
    useState,
    useRef
} from 'react'

function walk(obj: any, path: string) {
    let cp = { ...obj }
    const sp = path.split('.')
    for (let x = 0; x < sp.length; x++) {
        cp = cp && cp[sp[x]] ? cp[sp[x]] : null
    }

    return cp
}

function textAlign(align: string): string {
    switch (align) {
        case 'right':
            return ' text-right'
        case 'center':
            return ' text-center'
        default:
            return ' text-left'
    }
}

interface ThType {
    label: string
    className?: string
    dense?: boolean
    align?: string
}

function Th({
    label,
    className = '',
    dense = false,
    align = 'left'
}: ThType): React.JSX.Element {
    if (!dense) {
        className += ' datatable-th-normal'
    }

    className += textAlign(align)
    className = `datatable-th ${className ? ` ${className}` : ''}`

    return (
        <th className={className} scope="col">
            {label}
        </th>
    )
}

interface TrType {
    data: any
    className: string
    header: any[]
    onLineClick?: (data: any) => void
    onLineClickCheck?: (data: any) => boolean
}

function Tr({
    data,
    className = '',
    header,
    onLineClick,
    onLineClickCheck = () => true
}: TrType): React.JSX.Element {

    const tds: React.JSX.Element[] = []
    header.forEach((k, i) => {
        let value = null
        if (typeof k.slot === 'function') {
            value = k.slot(data)
        } else {
            const realValue = walk(data, k.key)
            if (k.formatter) {
                value = k.formatter(realValue)
            } else {
                value = realValue
            }
        }

        let className = k.className ? k.className : ''
        className += textAlign(k?.align)

        if(k.show || !k.hasOwnProperty('show')) {
            tds.push(
                <td className={`datatable-td ${className}`} key={`td-${data.id}-${k.key}`}>
                    {value}
                </td>
            )
        }
    })

    const onClickAvailable = onLineClickCheck(data)

    const onClick = () => {
        if(onLineClick && onClickAvailable) onLineClick(data)
    }

    if(onLineClick) {
        className += ' cursor-pointer'
    }

    return <tr className={className} onClick={onClick}>{ tds }</tr>
}

interface CollectionResponseType {
    total: number
    data: unknown[]
}

interface MessageType {
    onEmpty?: string
}

export interface DatatablePaging {
    count: number
    current: number
    byPage: number
}

export interface DatatableExposedRef{
    load: () => void
    setPage: (page: number) => void
    scrollTop: () => void
}

export interface TableType {
    header: unknown[]
    data?: unknown[]
    byPage?: number
    dense?: boolean
    service?: ((
        resolve: (data: CollectionResponseType) => void,
        param: { limit: number, offset: number },
        reject: () => void
    ) => void) | null
    message?: MessageType
    autoload?: boolean
    suspenseFallback?: React.JSX.Element | string
    className?: string
    onLineClick?: (data: any) => void
    onLineClickCheck?: (data: any) => boolean
    current?: number
    onPageChange?: (newState: DatatablePaging) => void
}

const DataTable = forwardRef(
    (
        {
            header = [],
            data = [],
            byPage = 20,
            dense = false,
            service = null,
            message,
            autoload = true,
            suspenseFallback,
            className = '',
            onLineClick,
            onLineClickCheck = () => true,
            current = 1,
            onPageChange = () => {}
        }: TableType,
        ref
    ) => {


        const [Idata, setIdata] = useState(data || [])
        const [suspense, setSuspense] = useState(false)
        const [paging, setPaging] = useState<DatatablePaging>({
            count: 0,
            current,
            byPage
        })
        const scrollZone = useRef<HTMLDivElement>()

        const resolver = (response: CollectionResponseType, page: number): void => {
            if(response.data?.length === 0 && response.total > 0) {
                callback(1)
            } else {
                setSuspense(false)
                setIdata(response.data)
                const newState = {...paging, current: page, count: response.total}
                setPaging(newState)
                onPageChange(newState)
            }
        }

        const rejecter = () => {
            setSuspense(false)
        }

        const fetchData = (page?: number) => {
            if(service) {
                const _page = page || paging.current
                setSuspense(true)
                service((data) => resolver(data, _page), {
                    limit: byPage,
                    offset: (_page - 1) * byPage,
                }, rejecter)
            }
        }

        const callback = (page?: number) => {
            if (service) fetchData(page)
        }

        useImperativeHandle(ref, () => {
            return {
                load: () => {
                    callback()
                },
                setPage: (page: number) => {
                    callback(page)
                },
                scrollTop: () => {
                    scrollZone.current?.scrollIntoView({ behavior: "instant" })
                }
            }
        })

        useEffect(() => {
            if (autoload) fetchData()
        }, [autoload])

        let listing = []
        if (Idata && Idata.length > byPage) {
            const start = (paging.current - 1) * byPage
            listing = Array.isArray(Idata) ? [...Idata].splice(start, byPage) : []
        } else {
            listing = Array.isArray(Idata) ? [...Idata] : []
        }

        let cn = className
        if (!dense) {
            cn += ' px-6 py-8'
        }

        return (
            <div className={`w-full container-position ${cn}`} ref={scrollZone}>
                <table className="datatable border-collapse table-fixed w-full text-sm text-left">
                    <thead className="sticky top-0 z-10 datatable-thead">
                        <tr>
                            {
                                header.map((d, i) => (
                                    d.show || !d.hasOwnProperty('show') ? <Th key={`th${i}`} {...d} /> : null
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className="relative z-0 datatable-tbody">
                        {
                            listing.map(d => (
                                    <Tr
                                        className="datatable-tr"
                                        data={d} header={header}
                                        key={`tr${d.id}`}
                                        onLineClick={onLineClick}
                                        onLineClickCheck={onLineClickCheck}
                                    />
                            ))
                        }
                        {
                            suspenseFallback && suspense && listing.length === 0  ? (
                                <tr>
                                    <td
                                        className="datatable-td datatable-td-suspense"
                                        colSpan={header.length}>
                                        { suspenseFallback }
                                    </td>
                                </tr>
                            ) : null
                        }
                        {
                            message?.onEmpty && listing.length === 0 ? (
                                <tr>
                                    <td
                                        className={`datatable-td datatable-td-empty ${className}`}
                                        colSpan={header.length}>
                                        { message.onEmpty }
                                    </td>
                                </tr>
                            ) : null
                        }

                        {
                            !suspense && !message?.onEmpty && listing.length === 0 ? (
                                <tr>
                                    <td
                                        className={`datatable-td datatable-td-empty`}
                                        colSpan={header.length}>
                                        Aucune donnée disponible
                                    </td>
                                </tr>
                            ) : null
                        }
                    </tbody>
                </table>
            </div>
        )
    }
)
DataTable.displayName = "DataTable"

export { DataTable }
