
export enum MonthsEnglish {
    January = "january",
    February = "february",
    March = "march",
    April = "april",
    May = "may",
    June = "june",
    July = "july",
    August = "august",
    September = "september",
    October = "october",
    November = "november",
    December = "december",
}

export enum MonthsFrench {
    January = "janvier",
    February = "février",
    March = "mars",
    April = "avril",
    May = "mai",
    June = "juin",
    July = "juillet",
    August = "août",
    September = "septembre",
    October = "octobre",
    November = "novembre",
    December = "décembre",
}

export function getMonthNumberToMonthLabel(num: number, lang = "fr") {
    if(lang === "fr") {
        switch(num) {
            case 1: return MonthsFrench.January
            case 2: return MonthsFrench.February
            case 3: return MonthsFrench.March
            case 4: return MonthsFrench.April
            case 5: return MonthsFrench.May
            case 6: return MonthsFrench.June
            case 7: return MonthsFrench.July
            case 8: return MonthsFrench.August
            case 9: return MonthsFrench.September
            case 10: return MonthsFrench.October
            case 11: return MonthsFrench.November
            case 12: return MonthsFrench.December
        }
    }

    switch(num) {
        case 1: return MonthsEnglish.January
        case 2: return MonthsEnglish.February
        case 3: return MonthsEnglish.March
        case 4: return MonthsEnglish.April
        case 5: return MonthsEnglish.May
        case 6: return MonthsEnglish.June
        case 7: return MonthsEnglish.July
        case 8: return MonthsEnglish.August
        case 9: return MonthsEnglish.September
        case 10: return MonthsEnglish.October
        case 11: return MonthsEnglish.November
        case 12: return MonthsEnglish.December
    }
}

export function UTCToLocaltime(date: string | Date): Date {
    const d = typeof date === 'string' ? new Date(date) : date
    return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)
}

export function dateFormatter(date: string | Date, formatter = 'DD/MM/YYYY à hh:mm'): string {
    const _date = date instanceof Date ? date.toISOString() : date
    const match = _date.match(/(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})T(?<hour>\d{2}):(?<minute>\d{2}):(?<second>\d{2})\.(?<millisecond>\d+)Z/)?.groups

    if (!match) {
        throw new Error('Invalid date format');
    }

    return formatter.replace(/YYYY|YY|MM|DD|hh|mmm|mm|ss/g, function(m) {
        switch(m) {
            case 'YYYY':
                return match.year
            case 'YY':
                return match.year.substring(2)
            case 'MM':
                return match.month
            case 'DD':
                return match.day
            case 'hh':
                return match.hour
            case 'mmm':
                return match.millisecond
            case 'mm':
                return match.minute
            case 'ss':
                return match.second
            default:
                return m
        }
    })
}

export function getFirstDayOfMonth(year: number, month: number, firstDay = 'monday') {
    const fd = new Date(year, month - 1, 1)
    const day = fd.getDay()
    return firstDay ? (day === 0 ? 6 : day - 1) : day
}

export function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)
}

export function getDaysInMonth(year: number, month: number) {
    if (month - 1 === 1 && isLeapYear(year)) {
        return 29
    } else {
        return new Date(year, month, 0).getDate()
    }
}

export function getMonthDetails(date: Date | string) {
    if(typeof date === 'string') {
        date = new Date(date)
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    return {
        date,
        year,
        month,
        current: date.getDate(),
        first: getFirstDayOfMonth(year, month),
        last: getDaysInMonth(year, month),
    }
}

export function isValidDate(date: string): boolean {
    const s = date.split('.').map(n => Number.parseInt(n, 10))
    const d = new Date()

    if(s.length === 0) {
        return false
    }

    if(s[1] > 12 || s[0] > 31 || s[3] > 2150) {
        return false
    }

    d.setFullYear(s[2])
    d.setMonth(s[1] - 1)
    d.setDate(s[0])

    return !Number.isNaN(d.getTime())
}

export function dayMonthYearToDate(date: string, separator = '.'): Date {
    const s = date.split(separator).map(n => Number.parseInt(n, 10))
    const d = new Date()
    d.setFullYear(s[2])
    d.setMonth(s[1] - 1)
    d.setDate(s[0])
    return d
}

export function getAge(dateString: string) {
    const [day, month, year] = dateString.split('.').map(Number)
    const dateNaissance = new Date(year, month - 1, day)
    const dateActuelle = new Date()
    let age = dateActuelle.getFullYear() - dateNaissance.getFullYear()
    if (
        dateActuelle.getMonth() < dateNaissance.getMonth() ||
        (
            dateActuelle.getMonth() === dateNaissance.getMonth() &&
            dateActuelle.getDate() < dateNaissance.getDate()
        )
    ) {
        age--
    }

    return age
}

export function parseDate(date: Date | string): Date {
    if (date instanceof Date) {
        return date;
    }
    // Replace separators with a single type and split the string
    const parts = date.replace(/\.|\/|-/g, '-').split('-')
    // Adjust parts to ensure correct format for Date constructor (YYYY-MM-DD)
    if (parts[2].length === 4) {
        return new Date(+parts[2], +parts[1] - 1, +parts[0])
    }

    return new Date(+parts[0], +parts[1] - 1, +parts[2])
}

export function getDiffYear(date1: Date | string, date2: Date | string = new Date(), withMonth = false): number {
    // Parse both dates
    const d1 = parseDate(date1)
    const d2 = parseDate(date2)

    // Calculate the difference in years
    let diff = d1.getFullYear() - d2.getFullYear()

    // Adjust if the first date is earlier in the year than the second date
    if (withMonth && (d1.getMonth() < d2.getMonth() || (d1.getMonth() === d2.getMonth() && d1.getDate() < d2.getDate()))) {
        diff--;
    }

    return diff
}