import { formatSize } from "./util"

export interface ApiResponseInterface<T> {
    _meta: {
        error: boolean
        status: number
        message?: string
        count?: number
    },
    data: T
}

const defaultFetchConf = {
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
}

export enum FormatListingRequestSortingEnum {
    Asc = 1,
    Desc = -1
}

export interface FormatListingRequestProps {
    offset: number
    limit: number
    populate?: string
    sorting?: Record<string, FormatListingRequestSortingEnum>
    query?: string,
    filters?: Record<string, any>,
    fields?: string[],
}

export type FormatListingRequestToApi = {
    limit?: number,
    offset?: number
    sort?: Record<string, FormatListingRequestSortingEnum>
    populate?: string
    filter?: Record<string, any>,
    search?: string,
}

export interface ProgressInterface {
    percent: number
    current: number
    max: number
    speed: number
}

export interface EventProgress {
    progress?: (params: ProgressInterface) => void
    end?: () => void
    start?: () => void
}

export default class Api {

    private token: string
    private defaultHeaders: Record<string, string>
    private headers: Record<string, string>
    private baseUrl = ''
    private static onUnauthorized : () => void = () => {}
    private static beforeCall: Record<string, () => boolean | string> = {}
    private progressCallback?: (params: ProgressInterface) => void
    private endCallback?: () => void
    private startCallback?: () => void
    private startingTime = 0
    private tokenRequired = true
    private rawResponse = false

    public static getBaseUrl(addOn: string = ''): string {
        return import.meta.env.VITE_APP_API_URL + import.meta.env.VITE_APP_API_VERSION + ( addOn ? '/' + addOn : '')
    }

    public static addBeforCall(name: string, callback: () => boolean | string) {
        Api.beforeCall[name] = callback
    }

    constructor(token?: string) {
        this.defaultHeaders = {
            'Content-Type': 'application/json; charset=UTF-8'
        }
        if(token) this.setToken(token)
        this.headers = {}
        this.baseUrl = ''
    }

    public setTokenRequired(required: boolean) {
        this.tokenRequired = required
        return this
    }

    public setRawResponse(raw: boolean) {
        this.rawResponse = raw
        return this
    }

    public setToken(token: string): Api {
        this.token = token
        this.defaultHeaders.Authorization = `Bearer ${token}`
        return this
    }

    public setBaseUrl(value: string) {
        this.baseUrl = value
        return this
    }

    public setHeader(headers: Record<string, string>): this {
        this.headers = headers
        return this
    }

    public buildHeader() {
        return { ...this.headers, ...this.defaultHeaders }
    }

    public static setOnUnauthorized(onUnauthorized: () => void) {
        Api.onUnauthorized = onUnauthorized
    }

    public getRaw(endpoint: string, params: Record<string, string> = {}): Promise<Blob> {
        let querystring = ''
        if (params) {
            querystring = this.toQueryString(params)
        }

        const url = (this.baseUrl ? this.baseUrl : Api.getBaseUrl()) + '/' + endpoint

        if (!params.headers) {
            params.headers = this.headers
        } else {
            Object.keys(this.headers).forEach((k) => (params.headers[k] = this.headers[k]))
        }

        const base = {
            method: 'GET'
        }

        return fetch(url, this.buildSetting(base, params)).then((response) => response.blob())
    }

    public getBlobContent(endpoint: string, params: Record<string, string> = {}): Promise<string | ArrayBuffer | null> {
        return this.getRaw(endpoint, params)
            .then((blob) => blob.text())
    }

    public getBase64(endpoint: string, params: Record<string, string> = {}): Promise<string | ArrayBuffer | null> {
        return this.getRaw(endpoint, params)
                .then((blob) => new Promise( (success, reject) => {
                    let reader = new FileReader()
                    reader.onload = function() {
                        success(this.result)
                    }
                    reader.onerror = function() {
                        reject('Impossible de lire le fichier')
                    }
                    reader.readAsDataURL(blob)
                }))
    }

    public get<T>(endpoint: string, params?: FormatListingRequestToApi): Promise<ApiResponseInterface<T>> {
        let querystring = ''
        if (params) {
            querystring = this.toQueryString(params)
        }

        return this.exec<T>(endpoint + querystring)
    }

    public post<T>(endpoint: string, data = {}, params = {}): Promise<ApiResponseInterface<T>> {
        const base = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: this.buildBody(data) // body data type must match "Content-Type" header
        }
        return this.exec(endpoint, this.buildSetting(base, params))
    }

    public patch<T>(endpoint: string, data = {}, params = {}): Promise<ApiResponseInterface<T>> {
        const base = {
            method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
            body: this.buildBody(data) // body data type must match "Content-Type" header
        }
        return this.exec(endpoint, this.buildSetting(base, params))
    }

    public put<T>(endpoint: string, data = {}, params = {}): Promise<ApiResponseInterface<T>> {
        const base = {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            body: this.buildBody(data) // body data type must match "Content-Type" header
        }
        return this.exec(endpoint, this.buildSetting(base, params))
    }

    public delete<T>(endpoint: string, data = {}, params = {}): Promise<ApiResponseInterface<T>> {
        const base = {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            body: this.buildBody(data) // body data type must match "Content-Type" header
        }
        return this.exec(endpoint, this.buildSetting(base, params))
    }

    public query<T>(endpoint: string, data = {}, params = {}): Promise<ApiResponseInterface<T>> {
        const base = {
            method: 'QUERY', // *GET, POST, PUT, DELETE, etc.
            body: this.buildBody(data) // body data type must match "Content-Type" header
        }
        return this.exec(endpoint, this.buildSetting(base, params))
    }

    public from<T, O>(entry: ApiResponseInterface<T[]>, etl: (data: T) => O): ApiResponseInterface<O[]> {
        return {
            ...entry,
            data: entry.data.map(d => etl(d))
        }
    }

    private exec<T>(endpoint: string, params: Record<string, string> = {}): Promise<ApiResponseInterface<T>> {
        if (!this.token && this.tokenRequired === true) {
            return new Promise<ApiResponseInterface<T>>((resolve) => {
                resolve({
                    _meta: {
                        error: true,
                        status: 403,
                        message: 'token not exists'
                    },
                    data: {}
                })
            })
        }

        const url = (this.baseUrl ? this.baseUrl : Api.getBaseUrl()) + '/' + endpoint

        if (!params.headers) {
            params.headers = this.headers
        } else {
            Object.keys(this.headers).forEach((k) => (params.headers[k] = this.headers[k]))
        }

        const controller = new AbortController()
        const base = {
            method: 'GET',
            signal: controller.signal
        }

        const request = this.buildSetting(base, params)
        const keys = Object.keys(Api.beforeCall)
        if(keys.length > 0) {
            const beforeCall = keys.map(k => {
                return new Promise<void>((resolve: () => void, reject: (reason?: any) => void) => {
                    const call = Api.beforeCall[k]()
                    if(call === true) {
                        resolve()
                    } else {
                        reject(call)
                    }
                })
            })
            return Promise.all(beforeCall).then(() => this.fetch(url, request, controller))
        } else {
            return this.fetch(url, request, controller)
        }
    }

    public progress(callback: (params: ProgressInterface) => void): Api {
        this.progressCallback = callback
        return this
    }

    private updateProgress(event: ProgressEvent<XMLHttpRequestEventTarget>) {
        if(this.progressCallback) {
            const elasped = (new Date().getTime() - this.startingTime) / 1000
            this.progressCallback({
                percent: event.loaded * 100 / event.total,
                current: event.loaded,
                max: event.total,
                speed: event.loaded / elasped
            })
        }
    }

    public end(callback: () => void): Api {
        this.endCallback = callback
        return this
    }

    private endProgress() {
        if(typeof this.endCallback === 'function') {
            this.endCallback()
        }
    }

    public start(callback: () => void): Api {
        this.startCallback = callback
        return this
    }

    private startProgress() {
        this.startingTime = new Date().getTime()
        if(typeof this.startCallback === 'function') {
            this.startCallback()
        }
    }

    private fetch(url: string, request: any, controller: AbortController) {
        return new Promise<ApiResponseInterface<any>>((resolve, reject) => {

            const xhr = new XMLHttpRequest()
            xhr.open(request.method, url, true)

            xhr.addEventListener("loadstart", () => this.startProgress(), false);
            //xhr.addEventListener("load", handleEvent);
            xhr.addEventListener("loadend", () => this.endProgress(), false)
            xhr.upload.addEventListener("progress", (e) => this.updateProgress(e), false)
            //xhr.addEventListener("error", handleEvent);
            //xhr.addEventListener("abort", handleEvent);

            // Définissez les en-têtes de la requête
            Object.keys(request.headers).forEach(key => {
                xhr.setRequestHeader(key, request.headers[key])
            });

            // Gérez l'annulation de la requête
            controller.signal.addEventListener('abort', () => {
                xhr.abort()
            })

            // Gérez les événements de la requête XMLHttpRequest
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    let data
                    try {
                        data = JSON.parse(xhr.responseText)
                    } catch (parseError) {
                        reject('Erreur lors de l\'analyse de la réponse JSON')
                        return
                    }

                    if(this.rawResponse) {
                        resolve(data)
                    } else {
                        if (data?._meta?.error) {
                            reject(data._meta.message || 'Erreur de l\'API')
                        } else {
                            if (data._meta.count !== undefined) {
                                resolve({ total: data._meta.count, data: data.data })
                            } else {
                                data._meta.controller = controller
                                resolve(data)
                            }
                        }
                    }
                } else {
                    if(xhr.status === 401) {
                        Api.onUnauthorized()
                    } else {
                        reject(`La requête a échoué avec le statut ${xhr.status}`)
                    }
                }
            };

            xhr.onerror = () => {
                reject('Erreur réseau lors de la requête')
            }

            // Envoyez la requête avec le corps approprié
            if (request.method === 'GET' || request.method === 'DELETE') {
                xhr.send()
            } else {
                xhr.send(request.body)
            }
        })
    }

    private buildBody(data: any) {
        return JSON.stringify(data)
    }

    private buildSetting(base, params) {
        let setting = { ...base, ...params, ...defaultFetchConf }
        setting.headers = this.buildHeader()
        return setting
    }

    private toQueryString(params: any) {
        const bracket = (k, root) => {
            return root ? `${root}[${k}]` : k
        }

        const toArray = (obj, root = '') => {
            let query = ''
            Object.keys(obj).forEach((k) => {
                const o = obj[k]
                let end = ''
                if (obj[k] instanceof Array) {
                    obj[k].forEach((e, i) => {
                        if (typeof e === 'object') {
                            end += toArray(e, `${root}[${k}][${i}]`)
                        } else {
                            end += `${root}[${k}][${i}]=${e}&`
                        }
                    })
                } else if (obj[k] instanceof RegExp) {
                    end = `${bracket(k, root)}=${obj[k].toString()}&`
                } else if (typeof o === 'object') {
                    end = toArray(o, bracket(k, root))
                } else {
                    end = `${bracket(k, root)}=${o}&`
                }
                query += end
            })
            return query
        }

        let query = `?${  toArray(params)}`
        return query.substring(0, query.length - 1)
    }

    public formatListingRequest({
        offset,
        limit,
        populate,
        query,
        filters,
        sorting,
        fields = []
    }: FormatListingRequestProps): FormatListingRequestToApi {
        let params:FormatListingRequestToApi = {
            limit,
            offset
        }

        if (sorting) {
            params.sort = sorting
        }

        if (populate) {
            params.populate = populate
        }

        if (filters && Object.keys(filters).length > 0) {
            params.filter = filters
        }

        if (query !== '') {
            params.search = query
        }

        return params
    }
}
