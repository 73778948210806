import React from 'react'
import { Svg } from './Svg'

export function SpeakerMuted(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="240" x2="192" y1="104" y2="152"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="240" x2="192" y1="152" y2="104"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="80" x2="80" y1="88" y2="168"/>
        </Svg>
    )
}
