import React from 'react'
import { Svg } from './Svg'

export function CalendarPlus(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <rect fill="none" height="176" rx="8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" width="176" x="40" y="40"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="176" x2="176" y1="24" y2="56"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="80" x2="80" y1="24" y2="56"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="40" x2="216" y1="88" y2="88"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="156" x2="100" y1="152" y2="152"/>
            <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="128" x2="128" y1="124" y2="180"/>
        </Svg>
    )
}
