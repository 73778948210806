import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { type CropOptions,useImageCropper } from "../../hooks"
import { Portal } from "../Portal"
import { FlipHorizontal, FlipVertical, XMark } from "../../icons"
import { Transition } from "../Transition"

interface ImageCropModal extends CropOptions {
    image?: string
    onCrop?: (image: string) => void
    classNameCropped?: string
}

export const ImageCropModal = memo(({
    image,
    onCrop = () => {},
    ratio = 1,
    classNameCropped = "rounded-full",
}: ImageCropModal) => {

    const ref = useRef()
    const [content, setContent] = useState(image)
    const [flipHorizontal, setFlipHorizontal] = useState(false)
    const [flipVertical, setFlipVertical] = useState(false)

    const [imageForCrop, cropped, save, imageRef] = useImageCropper(content, onCrop, (state) => [
        state.cropper,
        state.cropped,
        state.save,
        state.image
    ], {ratio, flipVertical, flipHorizontal})

    useEffect(() => {
        setContent(image)
    }, [image])

    const close = useCallback((e) => {
        if(e.target === e.currentTarget) {
            setContent(undefined)
        }
    }, [])

    const width = useMemo(
        () => imageRef?.current?.offsetWidth ? imageRef?.current?.offsetWidth : 'auto',
        [imageRef?.current?.offsetWidth]
    )

    const flipHorizontalHandle = () => {
        setFlipHorizontal(!flipHorizontal)
    }

    const flipVerticalHandle = () => {
        setFlipVertical(!flipVertical)
    }

    return (
        <>
            {
                image ?
                    <Portal visible={!!content}>
                        <div className="modal-wrapper z-[100] h-screen w-screen fixed flex items-center justify-center top-0 left-0">
                            <div className="modal-overlay h-screen w-screen flex items-center justify-center flex-1" onMouseDown={close}>
                                <div
                                    className="h-5/6 min-h-[22rem] min-w-[22rem] max-w-[83.33%] max-h-[83.33%] flex flex-col items-center bg-black/80 rounded-xl overflow-hidden shadow-lg shadow-black relative select-none"
                                    onMouseDown={(e) => e.stopPropagation()}
                                    style={ { width }}
                                    ref={ref}
                                >
                                    <div className="overflow-hidden flex flex-1 h-full items-center justify-center bg-color8" ref={ref}>
                                        {imageForCrop}
                                    </div>
                                    <div className="flex flex-col items-center relative bg-black w-full p-6">
                                        <div className={"-mt-[5.5rem] flex-1 w-36 h-36 overflow-hidden border-black border-8 relative shadow-[0_0_50px_0_rgba(0,0,0,1)] " + classNameCropped}>
                                            <img src={cropped} className="w-full h-full inline-block pointer-events-none select-none" />
                                        </div>
                                        <div className="pt-3">
                                            <Transition
                                                appear={true}
                                                show={!!content}
                                                enter="transition-all duration-200 delay-500"
                                                enterFrom="scale-0 opacity-0"
                                                enterTo="scale-1 opacity-100"
                                                leaveFrom="scale-1 opacity-100"
                                                leaveTo="scale-0 opacity-0"
                                            >
                                                <button onClick={save} className="text-white px-4 py-1 rounded-lg border border-white">Valider la sélection</button>
                                            </Transition>
                                        </div>
                                        <div
                                            className={"absolute top-1 ml-44 border-2 rounded-full p-1 transition-all duration-500 cursor-pointer " + (flipHorizontal ? "border-white" : "border-white/10 hover:border-white")}
                                            onClick={flipHorizontalHandle}
                                        >
                                            <FlipHorizontal className={"w-6 h-6 duration-200 transition-all " + (flipHorizontal ? "fill-white" : "fill-white/50 hover:fill-white")} />
                                        </div>
                                        <div
                                            className={"absolute top-11 ml-36 border-2 rounded-full p-1 transition-all duration-500 cursor-pointer " + (flipVertical ? "border-white" : "border-white/10 hover:border-white")}
                                            onClick={flipVerticalHandle}
                                        >
                                            <FlipVertical  className={"w-6 h-6 duration-200 transition-all " + (flipVertical ? "fill-white" : "fill-white/50 hover:fill-white")} />
                                        </div>
                                    </div>
                                    <div
                                        className={"absolute top-4 right-4 bg-white/10 p-2 rounded-full cursor-pointer shadow-lg shadow-black/20"}
                                        onClick={() => setContent(undefined)}
                                    >
                                        <XMark className="w-8 h-8 stroke-white" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Portal>
                : null
            }
        </>
    )
})