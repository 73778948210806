import React from 'react'
import { Svg } from './Svg'

export function FlipHorizontal(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 24 24">
            <path d="M4.5 17.5001C4.2355 17.5001 3.99056 17.3607 3.85537 17.1334C3.72018 16.9061 3.71473 16.6243 3.84103 16.3919L10.091 4.89194C10.2548 4.59052 10.6023 4.43867 10.9348 4.52318C11.2672 4.6077 11.5 4.90702 11.5 5.25007V16.7501C11.5 17.1643 11.1642 17.5001 10.75 17.5001H4.5Z"/>
            <path d="M20.1446 17.1334C20.0094 17.3607 19.7645 17.5001 19.5 17.5001H13.25C12.8358 17.5001 12.5 17.1643 12.5 16.7501V5.25007C12.5 4.90702 12.7328 4.6077 13.0652 4.52318C13.3977 4.43867 13.7452 4.59052 13.909 4.89194L20.159 16.3919C20.2853 16.6243 20.2798 16.9061 20.1446 17.1334ZM14 8.20071V16.0001H18.2388L14 8.20071Z" />
        </Svg>
    )
}

// Phosphor+user