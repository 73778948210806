import * as React from "react"
import { useCallback } from "react"
import { NavLink } from "react-router-dom"
import { ReactChildren } from "ui/types"

type Props = {
    children: ReactChildren,
    icon?: ReactChildren,
    url?: string,
    action?: () => void,
    className?: string,
    blank?: boolean
    onClick?: () => void
}

export const MenuLink = ({
    children,
    icon,
    url = '',
    action = () => {},
    className = '',
    blank = false,
    onClick
}: Props) => {

    className =
        'flex cursor-pointer items-center transition-all duration-500 rounded-md hover:bg-beaver/60 hover:text-white px-2 py-1 text-sm ' +
        (className !== null ? ' ' + className : ' mb-1 mx-4')

    let props = {}
    if (blank) {
        props = {
            target: '_blank',
            rel: 'noopener noreferrer'
        }
    }

    const trigger = useCallback(() => {
        if(onClick) onClick()
    }, [onClick])

    return url ? (
        <NavLink
            end
            to={url}
            className={({ isActive, isPending }) => {
                return (
                    className +
                    (isActive ? ' bg-beaver text-white' : ' dark:text-zinc-200 text-zinc-600') +
                    (isPending ? ' bg-amber-500 text-white' : '') +
                    (!isActive && !isPending ? ' text-zinc-400' : '')
                )
            }}
            {...props}
            onClick={trigger}
        >
            {icon ?? null}
            {children}
        </NavLink>
    ) : (
        <div onClick={action} className={className}>
            {icon ?? null}
            {children}
        </div>
    )
}