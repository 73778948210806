import React from 'react'
import { Svg } from './Svg'

export function Play(props): React.JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <path d="M228.1,121.2,84.2,33.2A8,8,0,0,0,72,40V216a8,8,0,0,0,12.2,6.8l143.9-88A7.9,7.9,0,0,0,228.1,121.2Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </Svg>
    )
}

// Phosphor+user

