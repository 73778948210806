import React from 'react'
import { Svg } from './Svg'

export function XMark(props) {
    return (
        <Svg {...props} viewBox="0 0 256 256">
            <line strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="200" x2="56" y1="56" y2="200"/>
            <line strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="200" x2="56" y1="200" y2="56"/>
        </Svg>
    )
}